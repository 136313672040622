import * as React from 'react';
import styled from 'styled-components';
import { WelcomeHeader } from './WelcomeHeader';
import { HeaderPhoneVideo } from './HeaderPhoneVideo';
import { WelcomePageFooter } from './WelcomePageFooter';
import { WelcomeSections } from './WelcomeSections';
import { NewProfileInput } from './NewProfileInput';

export const WelcomeContent = () => {
  return (
    <Container>
      <WelcomeHeader />
      <HeaderPhoneVideo />
      <StyledFirstNewProfileInput />
      <WelcomeSections />
      <StyledSecondNewProfileInput />
      <WelcomePageFooter />
    </Container>
  );
};

const StyledFirstNewProfileInput = styled(NewProfileInput)`
  margin: 0 0 4rem;

  @media (max-width: 400px) {
    margin: 0 0 1rem;
  }
`;

const StyledSecondNewProfileInput = styled(StyledFirstNewProfileInput)`
  margin: 4rem 0 1rem;

  @media (max-width: 400px) {
    margin: 0 0 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  user-select: none;
`;
