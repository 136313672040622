export const ROUTES = {
  WELCOME: '/',
  PRICING: '/pricing',
  BLOG: '/blog',
  HELP: '/help',
  USERS: '/users',
  LOGIN: '/login',
  REGISTER: '/register',
  PROFILE: '/profile',
  NOTFOUND: '/notfound',
};
