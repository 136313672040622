import * as React from 'react';
import { useNavigate } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../routing/routesConstants';
import { Button } from '../Button/Button';
import { useUserState } from '../../hooks/useUserState';

export const AuthorizationButtons = (props: { open: boolean }): JSX.Element => {
  const { open } = props;
  const { user } = useUserState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user) {
    return (
      <StyledButtons open={open}>
        <Button onClick={() => navigate(ROUTES.PROFILE)}>{t('DashboardButton')}</Button>
      </StyledButtons>
    );
  }

  return (
    <StyledButtons open={open}>
      <Button onClick={() => navigate(ROUTES.LOGIN)}>{t('LoginButton')}</Button>
      <Button onClick={() => navigate(ROUTES.REGISTER)}>{t('RegisterButton')}</Button>
    </StyledButtons>
  );
};

const StyledButtons = styled.div<{ open: boolean }>`
  display: flex;
  flex-flow: row;

  & > button {
    margin: ${({ open }) => (open ? '3px 3px' : '0 3px')};
  }

  @media (max-width: 768px) {
    flex-flow: column;
  }
`;
