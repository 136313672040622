// @flow
import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { LayoutDefault } from '../../components/Layouts/LayoutDefault';
import { WelcomeContent } from './WelcomeContent';

export const Welcome = (props: RouteComponentProps) => {
  return (
    <LayoutDefault>
      <WelcomeContent />
    </LayoutDefault>
  );
};
