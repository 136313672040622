import axiosInstance from '../helpers/axios';
import { setCurrentUser } from '../helpers/setCurrentUser';

export const register = (username: string, email: string, password: string) => {
  return axiosInstance
    .post('/users/users/', {
      username,
      email,
      password,
    })
    .then((response) => {
      if (response.data && response.data.id) {
        return response.data;
      }
      throw new Error('User not created.');
    })
    .catch((error) => {
      console.log('ERROR: ', error);
    });
};

export const login = (username: string, password: string) => {
  return axiosInstance
    .post(`/token/`, {
      username,
      password,
    })
    .then((response: any) => {
      if (response.data.access) {
        setCurrentUser(response.data);
      }

      return response.data;
    });
};
