import * as React from 'react';
import { SVGProps } from 'react';

export const PhoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.8337 2.30029H19.167C18.7439 2.30029 18.4004 2.64377 18.4004 3.06693C18.4004 3.49009 18.7439 3.83357 19.167 3.83357H26.8337C27.2568 3.83357 27.6003 3.49009 27.6003 3.06693C27.6003 2.64377 27.2568 2.30029 26.8337 2.30029Z"
        fill="white"
      />
      <path
        d="M32.2003 2.30029H31.4336C31.0105 2.30029 30.667 2.64377 30.667 3.06693C30.667 3.49009 31.0105 3.83357 31.4336 3.83357H32.2003C32.6234 3.83357 32.9669 3.49009 32.9669 3.06693C32.9669 2.64377 32.6234 2.30029 32.2003 2.30029Z"
        fill="white"
      />
      <path
        d="M23.9524 39.1001H22.0488C20.8827 39.1001 19.9336 40.0492 19.9336 41.2153V41.5856C19.9336 42.7517 20.8827 43.7001 22.0481 43.7001H23.9517C25.1178 43.7001 26.0669 42.7517 26.0669 41.5856V41.2153C26.0669 40.0492 25.1178 39.1001 23.9524 39.1001ZM24.5336 41.5856C24.5336 41.9061 24.273 42.1667 23.9525 42.1667H22.0488C21.7276 42.1667 21.4669 41.9061 21.4669 41.5856V41.2153C21.4669 40.8941 21.7275 40.6334 22.0488 40.6334H23.9517C24.273 40.6334 24.5336 40.894 24.5336 41.2153V41.5856V41.5856Z"
        fill="white"
      />
      <path
        d="M33.2627 0H12.7383C11.2103 0 9.9668 1.24353 9.9668 2.7715V43.2285C9.9668 44.7565 11.2103 46 12.7383 46H33.2619C34.7899 46 36.0334 44.7565 36.0334 43.2293V2.7715C36.0335 1.24353 34.79 0 33.2627 0ZM34.5002 43.2285C34.5002 43.9109 33.9451 44.4666 33.2627 44.4666H12.7383C12.0552 44.4666 11.5002 43.9108 11.5002 43.2292V2.7715C11.5002 2.08914 12.0552 1.53336 12.7383 1.53336H33.2619C33.945 1.53336 34.5001 2.08923 34.5001 2.7715V43.2285H34.5002Z"
        fill="white"
      />
      <path
        d="M35.2667 4.6001H10.7334C10.3103 4.6001 9.9668 4.94357 9.9668 5.36673V37.5667C9.9668 37.9899 10.3103 38.3334 10.7334 38.3334H35.2668C35.69 38.3334 36.0334 37.9899 36.0334 37.5667V5.36673C36.0334 4.94357 35.69 4.6001 35.2667 4.6001ZM34.5001 36.8001H11.5001V6.13346H34.5001V36.8001Z"
        fill="white"
      />
    </svg>
  );
};
