import React from 'react';
import styled, { css } from 'styled-components';

type TabProps = {
  index: number;
  isSelected: boolean;
  label: string;
  onClick: (index: number) => void;
};

export const Tab = (props: TabProps) => {
  const handleClick = () => {
    props.onClick(props.index);
  };

  return (
    <StyledTab isSelected={props.isSelected} onClick={handleClick}>
      <span className="tab__label">{props.label}</span>
    </StyledTab>
  );
};

const StyledTab = styled.span<{ isSelected: boolean }>`
  color: #96858f;
  cursor: pointer;
  display: inline-block;
  padding: 6px 24px;
  position: relative;
  text-transform: uppercase;

  :nth-child(n + 2) {
    //margin-left: -8px;
  }

  :hover {
    color: #6d7993;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #9099a2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #3e00ff;
      color: #3e00ff;
      font-size: 14px;
      font-weight: bold;
    `};
`;
