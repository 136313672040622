import * as React from 'react';
import styled from 'styled-components';
import { UserLinks } from './UserLinks';
import { UserIconLinks } from './UserIconLinks';
import { CardProfileType } from './CardProfileType';
import { sortByPriority } from '../../utils/sortByPriority';

type Props = {
  cardProfile: CardProfileType;
  className?: string;
};
export const UserPage = (props: Props) => {
  const { cardProfile } = props;

  const getLinks = () => {
    const links = cardProfile.links.filter((link: any) => link.is_active && !link.is_icon);
    return [...links].sort(sortByPriority);
  };

  const getIconLinks = () => {
    const links = cardProfile.links.filter((link: any) => link.is_active && link.is_icon);
    return [...links].sort(sortByPriority);
  };

  return (
    <Container className={props.className}>
      <UserItems>
        {cardProfile.profile_image && (
          <div>
            <ProfileImage src={cardProfile.profile_image} alt="userImage" />
          </div>
        )}
        <UserHeader>
          <h2>{cardProfile.displayname}</h2>
          {cardProfile.description && <Description>{cardProfile.description}</Description>}
        </UserHeader>
        <UserLinks links={getLinks()} />
        <UserIconLinks links={getIconLinks()} />
      </UserItems>
    </Container>
  );
};

const Description = styled.h4`
  white-space: pre-wrap;
`;

const UserHeader = styled.div`
  text-align: center;
  margin-bottom: 1em;

  > h2,
  > h4 {
    margin: 0;
    font-weight: 400;
  }
`;

const Container = styled.div`
  height: 100%;
`;

const UserItems = styled.div`
  padding-top: 4em;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ProfileImage = styled.img`
  display: inline-block;
  height: 6.2em;
  width: 6.2em;
  border-radius: 50%;
`;
