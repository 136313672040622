import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';
import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import { Dots } from '../../assets/icons/Dots';
import { shallowEqual } from '../../utils/shallowEqual';
import { ToggleButton } from '../../components/Toggle/ToggleButton';
import { SmallSpinner } from '../../components/Spinner/SmallSpinner';
import { TrashButton } from '../../assets/icons/TrashButton';
import { CameraIcon } from '../../assets/icons/CameraIcon';
import { LinkSet } from '../OneLink/CardProfileType';
import { LinkBoxFromValues } from './types/LinkBoxFromValues';
import { EditLinkInModal } from './EditLinkInModal';
import useWindowSize from '../../hooks/useWindowSize';

type LinkBoxProps = {
  id: number;
  name: string;
  url: string;
  icon: string | undefined;
  index: number;
  active: boolean;
  asIcon: boolean;
  save: (values: LinkBoxFromValues, id: number) => Promise<void>;
  onRemove: (id: number) => Promise<void>;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  updateLinkImage?: (link: LinkSet) => Promise<void>;
};

export const SocialLinkBox = (props: LinkBoxProps) => {
  const { id, name, url, active, asIcon, save, icon, onRemove, provided, snapshot } = props;

  const { t } = useTranslation();
  const initialValues: LinkBoxFromValues = { name, target: url, active, asIcon };
  const fileUploader = useRef<HTMLInputElement>(null);

  const width = useWindowSize();

  const onSubmit = (values: LinkBoxFromValues) => {
    if (!shallowEqual(initialValues, values) && formik.isValid) {
      save(values, id).then(() => {
        formik.setSubmitting(false);
      });
    } else {
      formik.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('RequiredField')),
    target: Yup.string().required(t('RequiredField')),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialStatus: 'NEW',
  });

  const getUrl = (target: string, toReplace: string) => {
    return target.replace(toReplace, '');
  };
  const [urlText] = useState(getUrl(formik.values.target, formik.values.name));

  const editProfileImage = () => {
    if (fileUploader.current) {
      fileUploader.current.click();
    }
  };

  const saveOnBlur = (e: React.FocusEvent) => {
    formik.handleBlur(e);
    if (!shallowEqual(initialValues, formik.values) && formik.isValid) {
      formik.setSubmitting(true);
      const dataToSave = {
        ...formik.values,
        target: `${urlText}${formik.values.name}`,
      };
      save(dataToSave, id).then(() => {
        formik.setSubmitting(false);
      });
    }
  };

  const removeLink = async () => {
    formik.setSubmitting(true);
    await onRemove(id);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setStatus('');
    formik.handleChange(e);
  };

  const saveInModal = (data: { name: string; url: string }) => {
    formik.setSubmitting(true);
    const newFormikValues = {
      ...formik.values,
      name: data.name,
      target: `${urlText}${data.name}`,
    };
    save(newFormikValues, id).then(() => {
      formik.setSubmitting(false);
      formik.setValues(newFormikValues);
    });
  };

  return (
    <LinkContainer
      {...provided?.draggableProps}
      ref={provided?.innerRef}
      isDraggable={snapshot ? snapshot.isDragging : false}
    >
      <Content>
        {formik.isSubmitting ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <DraggableElem {...provided?.dragHandleProps}>
            <StyledDots />
          </DraggableElem>
        )}
        <ImageContainer>
          {icon ? (
            <>
              <IconImage src={icon} />
            </>
          ) : (
            <>
              <CameraIcon onClick={editProfileImage} />
            </>
          )}
        </ImageContainer>
        <FormContainer>
          <InputsContainer>
            <UrlText>{urlText}</UrlText>
            <UrlName>
              <NameInput
                onChange={handleChange}
                name="name"
                type="text"
                value={formik.values.name}
                onBlur={saveOnBlur}
                placeholder="..."
                disabled={formik.isSubmitting || width <= 768}
              />
            </UrlName>
          </InputsContainer>
          <ActionButtons>
            <EditLinkOnMobileContainer>
              <EditLinkInModal name={name} url={url} icon={icon} onSuccess={saveInModal} />
            </EditLinkOnMobileContainer>
            <ToggleButton
              onChange={handleChange}
              name="active"
              checked={formik.values.active}
              onBlur={saveOnBlur}
              disabled={formik.isSubmitting}
            />
          </ActionButtons>
          <Content>
            <RemoveContainer onClick={removeLink}>
              <StyledTrashButton />
            </RemoveContainer>
          </Content>
          <input
            hidden
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          />
        </FormContainer>
      </Content>
    </LinkContainer>
  );
};

export const DraggableSocialLinkBox = (props: LinkBoxProps) => {
  const { id, index } = props;
  return (
    <Draggable draggableId={`${id}`} index={index}>
      {(provided, snapshot) => <SocialLinkBox {...props} provided={provided} snapshot={snapshot} />}
    </Draggable>
  );
};

const UrlText = styled.div`
  overflow-wrap: anywhere;
`;

const StyledTrashButton = styled(TrashButton)`
  width: 25px;
  height: 25px;
`;

const UrlName = styled.div`
  flex-grow: 1;
`;

const LinkContainer = styled.div<{ isDraggable: boolean }>`
  margin-top: 5px;
  border-radius: 10px;
  padding: 11px 14px 11px 14px;
  position: relative;
  border: ${({ isDraggable }) => (isDraggable ? '1px solid #BFC2C7' : '1px solid #dee3ed')};

  background: ${({ isDraggable }) => (isDraggable ? '#f2f2f2' : '#f2f3f8')};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDots = styled(Dots)`
  margin-right: 15px;
`;

const DraggableElem = styled.div<{ noDrag?: boolean }>``;

const Input = styled.input`
  background-color: transparent;
  border: 0;

  width: 100%;
  :focus {
    outline: none;
  }
`;
const NameInput = styled(Input)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  background: #edeff4;
  border: 1px dashed #e2e5eb;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  align-items: center;

  position: relative;
`;

const InputsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const Spinner = styled(SmallSpinner)`
  margin-right: 10px;
`;

const IconImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 0.25rem;
  border-radius: 10px;
`;

const ActionButtons = styled(Content)`
  margin-left: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditLinkOnMobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
`;
