import 'regenerator-runtime/runtime';
import './translations/i18n';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';
import { MainApp } from './MainApp';

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider placement="bottom-right" autoDismiss autoDismissTimeout={3000}>
      <MainApp />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
