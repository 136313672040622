import * as React from 'react';

type Props = {
  className?: string;
};
export const Dots = ({ className }: Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
      <circle cx="2" cy="2" r="2" fill="#E0E4EE" />
      <circle cx="7" cy="2" r="2" fill="#E0E4EE" />
      <circle cx="2" cy="8" r="2" fill="#E0E4EE" />
      <circle cx="7" cy="8" r="2" fill="#E0E4EE" />
      <circle cx="2" cy="14" r="2" fill="#E0E4EE" />
      <circle cx="7" cy="14" r="2" fill="#E0E4EE" />
    </svg>
  );
};
