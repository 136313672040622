import { useAxiosGet } from './useAxiosGet';
import { CardProfileType, UserCardProfileType } from '../pages/OneLink/CardProfileType';
import { UserType } from '../pages/Profile/types/UserType';

export const useCardProfileQuery = (cardProfileId: number) => {
  return useAxiosGet<CardProfileType>(`/cardprofiles/cardprofiles/${cardProfileId}/`);
};

export const useLinksTab = () => {
  return useAxiosGet<{ id: number }>(`/links/linkstabs/`);
};

export const useCardProfileQueryByUserName = (cardname: string) => {
  const endPointUrl = `/cardprofiles/cardprofiles/?cardname=${cardname}`;
  return useAxiosGet<CardProfileType>(endPointUrl);
};

export const useUsersQuery = (userId: string) => {
  return useAxiosGet<UserType>(`/users/users/${userId}/`);
};

export const useTopQuery = () => {
  return useAxiosGet<UserCardProfileType[]>(`/cardprofiles/top/`);
};
