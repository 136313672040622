import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { Input, Form } from '../../components/FormTheme';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: { name: string; url: string }) => void;
  name: string;
  target: string;
  externalModalTitle?: string;
};

export const EditLinkOnMobileModal = (props: Props) => {
  const { open, onClose, onSuccess, name, target, externalModalTitle } = props;
  const [error, setError] = useState<string>('');
  const [linkName, setLinkName] = useState<string>(name);
  const [linkTarget, setLinkTarget] = useState<string>(target);
  const { t } = useTranslation();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLinkName(event.target.value);
    setError('');
  };

  const onTargetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLinkTarget(event.target.value);
    setError('');
  };

  const editLink = async () => {
    if (!linkName) {
      setError('Link name is required!');
      return;
    }

    if (!linkTarget) {
      setError('Link target is required!');
      return;
    }

    onSuccess({ name: linkName, url: linkTarget });
  };

  useEffect(() => {
    setError('');
  }, [onSuccess, onClose]);

  const CreateEditLinksButtons = () => {
    return (
      <>
        <StyledButton onClick={editLink}>{t('Save')}</StyledButton>
        <Button onClick={onClose}>{t('Cancel')}</Button>
      </>
    );
  };

  return open ? (
    <Modal
      size="small"
      footerContent={<CreateEditLinksButtons />}
      id="createNewCardProfile"
      title={externalModalTitle || t('EditLinksTitle')}
    >
      <Container>
        <Form>
          <span>{t('Tabs.Settings.NameLabel')}</span>
          <StyledInput onChange={onNameChange} name="name" type="text" value={linkName} placeholder="" />
          <span>{t('Tabs.Settings.AddressLabel')}</span>
          <StyledInput onChange={onTargetChange} name="target" type="text" value={linkTarget} placeholder="" />
        </Form>
        {error && <ErrorBox>{error}</ErrorBox>}
      </Container>
    </Modal>
  ) : (
    <></>
  );
};

const StyledInput = styled(Input)`
  text-align: center;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
`;

const ErrorBox = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #cb2c2c;
  color: white;
`;
