// @flow
import * as React from 'react';

export const EditProfileImageIcon = () => {
  return (
    <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="49.5" cy="49.5" r="49.5" fill="#5E5E5E" fillOpacity="0.72" />
      <g clipPath="url(#clip0)">
        <path
          d="M63.4577 53.7304C63.0037 53.7304 62.6358 54.0983 62.6358 54.5523V61.8496C62.6342 63.2108 61.5314 64.3139 60.1702 64.3152H37.1094C35.7481 64.3139 34.6453 63.2108 34.6437 61.8496V40.4325C34.6453 39.0716 35.7481 37.9684 37.1094 37.9668H44.4067C44.8606 37.9668 45.2285 37.5989 45.2285 37.145C45.2285 36.6913 44.8606 36.3231 44.4067 36.3231H37.1094C34.8409 36.3257 33.0026 38.164 33 40.4325V61.8499C33.0026 64.1184 34.8409 65.9567 37.1094 65.9593H60.1702C62.4387 65.9567 64.277 64.1184 64.2796 61.8499V54.5523C64.2796 54.0983 63.9117 53.7304 63.4577 53.7304V53.7304Z"
          fill="white"
        />
        <path
          d="M63.9541 34.2084C62.5097 32.764 60.168 32.764 58.7236 34.2084L44.0609 48.8711C43.9605 48.9716 43.8879 49.0961 43.85 49.2329L41.9218 56.1941C41.8425 56.4795 41.9231 56.7851 42.1324 56.9948C42.3421 57.2041 42.6477 57.2847 42.9331 57.2057L49.8943 55.2772C50.0311 55.2393 50.1556 55.1667 50.2561 55.0663L57.5873 47.7347L64.9185 40.4032C66.3606 38.9579 66.3606 36.6181 64.9185 35.1728L63.9541 34.2084ZM45.8517 49.4053L57.852 37.4047L61.7222 41.2749L49.7216 53.2755L45.8517 49.4053ZM45.0787 50.9566L48.1706 54.0489L43.8937 55.2338L45.0787 50.9566ZM63.7563 39.2411L62.8847 40.1127L59.0142 36.2422L59.8861 35.3706C60.6884 34.5683 61.9893 34.5683 62.7916 35.3706L63.7563 36.335C64.5573 37.1382 64.5573 38.4381 63.7563 39.2411Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="33" height="33" fill="white" transform="translate(33 33)" />
        </clipPath>
      </defs>
    </svg>
  );
};
