import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { Modal } from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { Input, Form } from '../../components/FormTheme';
import { SelectSocial } from '../../components/SelectSocial/SelectSocial';
import links from '../../components/SelectSocial/links';
import { socials } from '../../components/SelectSocial/data';
import { LinkBoxFromValues } from './types/LinkBoxFromValues';
import { createNewLink, getLink, uploadLinkFile } from '../../helpers/endpoints';
import { convertToCreateLinkInput } from '../../types/CreateLinkInput';
import { LinkSet, LinksTab } from '../OneLink/CardProfileType';
import { svgToPng } from '../../utils/svgToPng';
import { getByteArrays } from '../../utils/getByteArrays';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';

type Props = {
  cardProfileId: number;
  open: boolean;
  onClose: () => void;
  updateState: (updatedLink: LinkSet) => Promise<void>;
};

export const AddSocialLinkOnMobileModal = (props: Props) => {
  const { open, onClose, updateState, cardProfileId } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [socialType, setSocialType] = useState<string>();
  const [linkName, setLinkName] = useState<string>();
  const { t } = useTranslation();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLinkName(event.target.value);
    setError('');
  };

  useEffect(() => {
    setError('');
  }, [updateState, onClose]);

  const CreateEditLinksButtons = () => {
    return (
      <>
        <StyledButton onClick={addNewLink}>{t('Save')}</StyledButton>
        <Button onClick={onClose}>{t('Cancel')}</Button>
      </>
    );
  };

  const addNewLink = async () => {
    if (!linkName || !socialType) {
      setError('Link name is required!');
      return;
    }

    setSubmitting(true);

    const svg = socials.find((s) => s.name === socialType);
    if (svg) {
      const saveData = {
        name: linkName,
        target: `${getUrl(socialType)}${linkName}`,
      };

      const toSave: LinkBoxFromValues = {
        name: saveData.name,
        target: saveData.target,
        asIcon: true,
        active: true,
      };
      const result = await createNewLink(convertToCreateLinkInput(toSave, cardProfileId, LinksTab.SOCIAL_LINK));

      const formData = new FormData();
      const str = renderToStaticMarkup(svg.icon);
      svgToPng(str, async (imgData: string) => {
        const file: File = new File(getByteArrays(imgData), 'icon-social.png', { type: 'image/png' });
        formData.append('thumbnail', file, file.name);

        await uploadLinkFile(result.data.id, formData);
        const updatedLink = await getLink(result.data.id);

        updateState(updatedLink.data);
      });
    }
  };

  const onChangeSocial = (value: string) => {
    setSocialType(value);
  };
  const getUrl = (social: string) => links[social] || 'Nazwa';

  return open ? (
    <Modal size="small" footerContent={<CreateEditLinksButtons />} id="createNewCardProfile" title={t('AddLinksTitle')}>
      <Container>
        {submitting ? (
          <CentredSpinner />
        ) : (
          <>
            <Form>
              <ImageContainer>
                <SelectSocial onChangeSocial={onChangeSocial} />
              </ImageContainer>
              <div>{getUrl(socialType || '')}</div>
              <InputsContainer>
                <UrlName>
                  <NameInput onChange={onNameChange} name="socialName" type="text" value={linkName} placeholder="..." />
                </UrlName>
              </InputsContainer>
            </Form>
            {error && <ErrorBox>{error}</ErrorBox>}
          </>
        )}
      </Container>
    </Modal>
  ) : (
    <></>
  );
};

const NameInput = styled(Input)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
`;

const UrlName = styled.div`
  flex-grow: 1;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  background: #edeff4;
  border: 1px dashed #e2e5eb;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  align-items: center;

  position: relative;
  cursor: pointer;
`;

const InputsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const ErrorBox = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #cb2c2c;
  color: white;
`;
