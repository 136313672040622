// @flow
import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { UserCardProfileType } from '../OneLink/CardProfileType';
import { CardProfileInfo } from './CardProfileInfo';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';
import { searchCardProfiles } from '../../helpers/endpoints';
import { NoProfiles } from './NoProfiles';

type Props = {
  topCardProfiles: UserCardProfileType[];
};
export const UsersComponent = (props: Props) => {
  const [search, setSearch] = useState<string>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserCardProfileType[]>();

  const getTopList = () => {
    return getList(props.topCardProfiles, true);
  };

  useEffect(() => {
    if (search && search.length >= 3) {
      fetchCardProfiles(search);
    } else {
      setData(undefined);
    }
  }, [search, setSearch]);

  const getSearchList = () => {
    return (
      <>
        {isLoading && (
          <SpinnerContainer>
            <CentredSpinner />
          </SpinnerContainer>
        )}
        {data ? getList(data) : <EmptyContainer />}
      </>
    );
  };

  const fetchCardProfiles = async (query: string) => {
    setIsLoading(true);
    const response = await searchCardProfiles(query);
    setData(response.data);
    setIsLoading(false);
    return response.data;
  };

  const getList = (elements: UserCardProfileType[], withNumbers = false) => {
    if (elements.length === 0) return <NoProfiles search={search} />;

    return elements.map((cardProfile, index) => (
      <CardContainer key={cardProfile.id} onClick={() => navigate(cardProfile.name)}>
        <CardProfileInfo cardProfile={cardProfile} elemNumber={withNumbers ? index + 1 : undefined} />
      </CardContainer>
    ));
  };

  return (
    <Container>
      <SearchInput
        placeholder={t('UserSearchPlaceholder')}
        onChange={(e) => setSearch(e.target.value)}
        value={search || ''}
      />
      <CardsListContainer>{search && search.length >= 3 ? getSearchList() : getTopList()}</CardsListContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background: rgb(255 255 255 / 40%);
`;

const CardsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  position: relative;
`;

const EmptyContainer = styled.div`
  width: 325px;
  height: 260px;
`;

const SearchInput = styled.input`
  width: 85%;
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #dee3ed;
  padding: 10px;
  font-size: 18px;
`;

const CardContainer = styled.div`
  margin: 10px;
  cursor: pointer;
`;
