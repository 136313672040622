import React, { FunctionComponent } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LayoutDefault } from '../../components/Layouts/LayoutDefault';
import { LoginForm } from './LoginForm';
import logo from '../../assets/images/logo.png';
import { ROUTES } from '../../routing/routesConstants';

export const Login: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <LayoutDefault>
      <Container>
        <Logo src={logo} />
        <HeaderText>{t('LoginPage.Header')}</HeaderText>
        <StyledLoginForm />
        <DontHaveAccount onClick={() => navigate(ROUTES.REGISTER)}>{t('LoginPage.DontHaveAccount')}</DontHaveAccount>
      </Container>
    </LayoutDefault>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`;

const Logo = styled.img`
  height: 48px;
`;

const HeaderText = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin-top: 11px;
`;

const StyledLoginForm = styled(LoginForm)`
  margin-top: 36px;
`;

const DontHaveAccount = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-top: 47px;

  cursor: pointer;
`;
