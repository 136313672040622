import * as React from 'react';
import styled from 'styled-components';
import { LayoutDefault } from './LayoutDefault';

export const withLayoutDefault = (children: JSX.Element) => {
  return (
    <LayoutDefault>
      <Container>{children}</Container>
    </LayoutDefault>
  );
};

const Container = styled.div`
  padding: 0;
`;
