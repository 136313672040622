import * as React from 'react';
import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const RemoveProfileImage = (props: Props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill="#9A2323" />
      <g clipPath="url(#clip0)">
        <path
          d="M26.8101 9.95309L26.3038 8.4354C26.111 7.85737 25.572 7.46898 24.9628 7.46898H20.7075V6.08355C20.7075 5.3911 20.1445 4.82759 19.4522 4.82759H15.5554C14.8633 4.82759 14.3001 5.3911 14.3001 6.08355V7.46898H10.045C9.43563 7.46898 8.89661 7.85737 8.70382 8.4354L8.19751 9.95309C8.08222 10.2986 8.14065 10.6812 8.35357 10.9767C8.56648 11.2722 8.91096 11.4487 9.2752 11.4487H9.80443L10.9692 25.8525C11.0558 26.9213 11.9628 27.7586 13.0344 27.7586H22.2096C23.281 27.7586 24.1881 26.9213 24.2745 25.8524L25.4394 11.4487H25.7324C26.0966 11.4487 26.4411 11.2722 26.654 10.9769C26.8669 10.6814 26.9254 10.2986 26.8101 9.95309V9.95309ZM15.6437 6.1712H19.3639V7.46898H15.6437V6.1712ZM22.9353 25.7441C22.9049 26.1204 22.5861 26.415 22.2096 26.415H13.0344C12.6579 26.415 12.3391 26.1204 12.3087 25.7441L11.1524 11.4487H24.0914L22.9353 25.7441ZM9.56335 10.1051L9.9785 8.86053C9.98795 8.83184 10.0147 8.8126 10.045 8.8126H24.9628C24.9931 8.8126 25.0196 8.83184 25.0293 8.86053L25.4444 10.1051H9.56335Z"
          fill="white"
        />
        <path
          d="M20.7924 25.6965C20.8043 25.6972 20.816 25.6974 20.8279 25.6974C21.1829 25.6974 21.4796 25.4192 21.4981 25.0605L22.129 12.9502C22.1482 12.5796 21.8634 12.2635 21.4931 12.2442C21.1216 12.2245 20.8066 12.5096 20.7871 12.8802L20.1564 24.9906C20.1372 25.3611 20.4218 25.6772 20.7924 25.6965V25.6965Z"
          fill="white"
        />
        <path
          d="M13.5393 25.0621C13.5589 25.4202 13.8553 25.6974 14.2095 25.6974C14.2218 25.6974 14.2344 25.697 14.2468 25.6963C14.6172 25.6762 14.9011 25.3595 14.881 24.989L14.2202 12.8786C14.2001 12.5081 13.8834 12.2241 13.5129 12.2444C13.1425 12.2645 12.8586 12.5812 12.8787 12.9517L13.5393 25.0621Z"
          fill="white"
        />
        <path
          d="M17.5114 25.6974C17.8825 25.6974 18.1832 25.3966 18.1832 25.0256V12.9152C18.1832 12.5441 17.8825 12.2434 17.5114 12.2434C17.1403 12.2434 16.8396 12.5441 16.8396 12.9152V25.0256C16.8396 25.3966 17.1403 25.6974 17.5114 25.6974Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22.931" height="22.931" fill="white" transform="translate(6.03442 4.82759)" />
        </clipPath>
      </defs>
    </svg>
  );
};
