import * as React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from '@reach/router';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text, Label } from '../../components/FormTheme';
import { login } from '../../services/authentication.service';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { ROUTES } from '../../routing/routesConstants';
import { useUserState } from '../../hooks/useUserState';
import { useUserDispatch } from '../../hooks/useUserDispatch';
import { Button } from '../../components/Button/Button';
import { StyledInput } from '../../components/StyledInput/StyledInput';

interface LoginFormValues {
  username: string;
  password: string;
}

export const LoginForm = ({ className }: { className?: string }) => {
  const initialValues: LoginFormValues = { username: '', password: '' };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUserState();
  const dispatch = useUserDispatch();

  const onSubmit = (
    values: LoginFormValues,
    { setStatus, setSubmitting, setValues }: FormikHelpers<LoginFormValues>,
  ) => {
    const { username, password } = values;

    login(username, password).then(
      () => {
        dispatch({ type: 'login' });
        navigate('/profile');
      },
      () => {
        setStatus('Twoje dane logowania są nieprawidłowe');
        setSubmitting(false);
        setValues({ username, password: '' });
      },
    );
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(t('RequiredField')),
    password: Yup.string()
      .required(t('RequiredField'))
      .min(8, t('PasswordToMin', { min: '8' })),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (user) {
      navigate(ROUTES.PROFILE);
    }
  }, []);

  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      {formik.status && <Text color="red">{formik.status}</Text>}
      {formik.isSubmitting && <AbsoluteSpinner />}
      <Label>
        {formik.touched.username && formik.errors.username && <Text color="red">{formik.errors.username}</Text>}
        <StyledInput
          labelName={t('UsernameLabel')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          type="text"
          name="username"
        />
      </Label>
      <Label>
        {formik.touched.password && formik.errors.password && <Text color="red">{formik.errors.password}</Text>}
        <StyledInput
          labelName={t('PasswordLabel')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          type="password"
          name="password"
        />
      </Label>
      <StyledButton type="submit">{t('LoginButton')}</StyledButton>
    </form>
  );
};

const StyledButton = styled(Button)`
  width: 300px;
`;
