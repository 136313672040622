import * as React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react';

const modalRoot = document.getElementById('modals');

type Props = {
  id: string;
  children?: JSX.Element;
  footerContent: JSX.Element;
  headerButton?: JSX.Element;
  title: string;
  modalClass?: string;
  className?: string;
  size: 'small' | 'normal' | 'large' | 'auto';
};

export const Modal = (props: Props) => {
  const { id, children, footerContent, title, modalClass, size, headerButton, className } = props;
  const background = useRef<HTMLDivElement>(null);
  const [fadeType, setFadeType] = useState<'in' | 'out'>('in');

  useEffect(() => {
    return () => {
      setFadeType('out');
    };
  }, []);

  const result: JSX.Element = (
    <ModalStyled size={size} id={id} className={`wrapper fade-${fadeType} ${modalClass} ${className}`} role="dialog">
      <div className="box-dialog">
        <div className="box-header">
          <h4 className="box-title">{title}</h4>
          {headerButton}
        </div>
        <div className="box-content">{children}</div>
        <div className="box-footer">{footerContent}</div>
      </div>
      <div className="background" ref={background} />
    </ModalStyled>
  );

  if (modalRoot) {
    return ReactDOM.createPortal(result, modalRoot);
  }
  return result;
};

Modal.defaultProps = {
  size: 'large',
};

const ModalStyled = styled.div<{ size: 'small' | 'normal' | 'large' | 'auto' }>`
  img {
    max-width: 100%;
    max-height: 60vh;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  z-index: 2000;
  @media (max-width: 768px) {
    width: 100vw;
  }
  
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '500px;';
      case 'normal':
        return '700px;';
      case 'large':
        return '900px;';
      case 'auto':
      default:
        return '60%;';
    }
  }}    

  margin: 40px auto;
  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    z-index: 1050;
    width: 100%;
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    .box-content {
      padding: 24px;
      display: flex;
      justify-content: center;
    }
    .box-header {
      height: 48px;
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #c7c7c7;
      .box-title {
        font-size: 24px;
        font-weight: 400;
        margin: 0 0 0 0;
      }
      .x-close {
        font-size: 35px;
        line-height: 35px;
        font-weight: 400;
        text-shadow: none;
        color: black;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .box-body {
      font-size: 14px;
      padding: 0px;
      width: auto;
      height: auto;
    }
    .box-footer {
      height: 40px;
      padding: 20px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #c7c7c7;
    }
  }
`;
