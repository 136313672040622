import * as React from 'react';
import styled from 'styled-components';
import logo_mini from '../../assets/images/logo_mini.svg';
import { LinkSet } from './CardProfileType';

type Props = {
  links: LinkSet[];
};
export const UserIconLinks = (props: Props) => {
  const { links } = props;
  return (
    <Container>
      {links.map((link) => {
        const iconSrc = link.thumbnail ? link.thumbnail : logo_mini;
        return (
          <BoxContainer key={link.id} href={link.target} target="_blank">
            <StyledImg src={iconSrc} alt="icon" />
          </BoxContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: center;
`;

const StyledImg = styled.img`
  height: 1.3em;
`;

const BoxContainer = styled.a`
  padding: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-radius: 20%;
  margin: 5px;
`;
