import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { ROUTES } from '../../routing/routesConstants';
import logo from '../../assets/images/logo.png';
import { Burger } from './Burger';
import { NavBarItems } from './NavBarItems';
import { LanguageChanger } from '../LanguageChanger/LanguageChanger';
import Dropdown from './Dropdown';
import defaultProfileImage from '../../assets/images/default-profile-image.svg';

type Props = {
  profileImage?: string;
  className?: string;
  userLoggedIn?: boolean;
};

const Navbar = (props: Props) => {
  const { className, profileImage, userLoggedIn } = props;

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const node = useRef<HTMLElement>(null);

  const handleClickOutsideNav = (e: MouseEvent) => {
    const { current } = node;
    if (current && current.contains(e.target as Node)) {
      return;
    }
    setClick(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideNav);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNav);
    };
  }, []);

  return (
    <NavContainer className={className} ref={node}>
      <LogoContainer onClick={() => navigate(ROUTES.WELCOME)}>
        <Logo src={logo} />
      </LogoContainer>

      <BurgerContainer onClick={handleClick}>
        <Burger open={click} />
      </BurgerContainer>

      <NavBarItems open={click} />

      {userLoggedIn ? (
        <ProfileImageContainer>
          <ProfileImage src={profileImage || defaultProfileImage} alt="userImage" />
          <StyledDropdown />
        </ProfileImageContainer>
      ) : (
        <StyledLanguageChanger />
      )}
    </NavContainer>
  );
};

export default Navbar;

const StyledDropdown = styled(Dropdown)``;

const ProfileImageContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  :hover ${StyledDropdown} {
    display: block;
  }
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 15px;
`;

const StyledLanguageChanger = styled(LanguageChanger)`
  display: flex;
  margin: 0 15px;
`;

const NavContainer = styled.nav`
  background-color: #f8f9fa;
  height: 56px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
`;

const LogoContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translate(0%, -50%);
  display: flex;
  width: 150px;
`;

const BurgerContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 1.5rem;
    transform: translate(-100%, 70%);
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: 24px;
`;
