import * as React from 'react';
import { UserDispatchContext } from '../helpers/AppContext';

export function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used');
  }
  return context;
}
