// @flow
import * as React from 'react';
import styled from 'styled-components';
import { LinkSet } from './CardProfileType';

type Props = {
  links: LinkSet[];
};
export const UserLinks = (props: Props) => {
  const { links } = props;

  const getThumbnail = (thumbnail: string) => {
    if (thumbnail) {
      return (
        <LinkImg>
          <StyledImg src={thumbnail} alt="icon" />
        </LinkImg>
      );
    }
    return <></>;
  };

  return (
    <>
      {links
        .filter((link: any) => link.is_active)
        .map((link) => (
          <LinkContainer key={link.id} href={link.target} target="_blank">
            {getThumbnail(link.thumbnail)}
            <LinkName>{link.name}</LinkName>
          </LinkContainer>
        ))}
    </>
  );
};

const LinkName = styled.div`
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 10px 50px;
`;

const LinkContainer = styled.a`
  border: 1px solid #007bff;
  border-radius: 5px;
  width: 80%;
  margin: 0.5em 0;
  min-height: 2em;
  cursor: pointer;
  text-align: center;
  position: relative;
  text-decoration: none;
  display: flex;

  &:hover {
    background-color: #007bff;
    transition: 0.3s ease;
  }

  &:hover ${LinkName} {
    color: #fff;
  }
`;

const StyledImg = styled.img`
  height: 1.3em;
  width: 1.3em;
`;

const LinkImg = styled.div`
  position: absolute;
  padding: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border: 1px solid #dee2e6;
  border-radius: 20%;
  margin: 0.25rem 0.5rem;
  width: 1.3em;
`;
