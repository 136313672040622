import * as React from 'react';
import styled from 'styled-components';
import NavBar from '../NavBar/NavBar';
import { AbsoluteSpinner } from '../Spinner/AbsoluteSpinner';
import { useCardProfileQuery } from '../../hooks/queriesHooks';

interface LayoutDefaultProps {
  cardProfileId?: number;
  pageTitle?: string;
  tabs?: React.ReactNode;
}

export const LayoutDefaultInside: React.FC<LayoutDefaultProps> = ({ children, cardProfileId }) => {
  let profileImage: string | undefined;
  let userLoggedIn = false;
  if (cardProfileId) {
    const [data, loading] = useCardProfileQuery(cardProfileId);
    if (!data || loading) return <AbsoluteSpinner />;
    profileImage = data.profile_image;
    userLoggedIn = true;
  }

  return (
    <Wrapper>
      <Main>
        <StyledNavBar profileImage={profileImage} userLoggedIn={userLoggedIn} />
        <Content>
          <Centered>{children}</Centered>
        </Content>
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const Centered = styled.div`
  margin: auto;
  padding: 20px 0;
`;

const Main = styled.div`
  display: grid;
`;

const StyledNavBar = styled(NavBar)`
  grid-row: 1;
  position: fixed;
  width: 100vw;
`;

const Content = styled.div`
  grid-row: 2;
  padding-top: 52px;
`;
