import * as React from 'react';
import jwt_decode from 'jwt-decode';
import { useUserState } from '../../hooks/useUserState';
import { useUsersQuery } from '../../hooks/queriesHooks';
import { AbsoluteSpinner } from '../Spinner/AbsoluteSpinner';
import { LayoutDefaultInside } from './LayoutDefaultInside';

export const LayoutDefault: React.FC = ({ children }) => {
  const { user } = useUserState();

  let cardProfileId: number | undefined;
  if (user) {
    const decode: { user_id: string } = jwt_decode(user);
    const [data, loading, error] = useUsersQuery(decode.user_id);
    if (error) {
      console.log('Error LayoutDefault: ', error);
    }
    if (!data || loading) return <AbsoluteSpinner />;

    if (data && data.cardprofile_set.length !== 0) {
      const defaultCardProfile = data.cardprofile_set[0];
      if (defaultCardProfile) {
        cardProfileId = defaultCardProfile.id;
      }
    }
  }

  return <LayoutDefaultInside cardProfileId={cardProfileId}>{children}</LayoutDefaultInside>;
};
