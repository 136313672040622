import { CardProfileSettingsInput } from 'types/CardProfileSettingsInput';
import { UpdateLinkInput } from '../types/UpdateLinkInput';
import axiosInstance from './axios';
import { CardProfileInput } from '../types/CardProfileInput';

export const updateLinkPatch = (linkId: number, values: UpdateLinkInput) => {
  return axiosInstance.patch(`/links/links/${linkId}/`, values);
};

export const createNewLink = (values: UpdateLinkInput) => {
  return axiosInstance.post(`/links/links/`, values);
};

export const removeLinkQuery = (linkId: number) => {
  return axiosInstance.delete(`/links/links/${linkId}/`);
};

export const updateLinksArray = (values: UpdateLinkInput[]) => {
  return axiosInstance.patch(`/links/links/`, values);
};

export const updateCardProfile = (cardProfileId: number, value: CardProfileInput) => {
  return axiosInstance.patch(`/cardprofiles/cardprofiles/${cardProfileId}/`, value);
};

export const updateCardProfileSettings = (cardProfileId: number, value: CardProfileSettingsInput) => {
  return axiosInstance.patch(`/cardprofiles/cardprofiles/${cardProfileId}/`, value);
};

export const uploadProfileFile = (cardProfileId: number, formData: FormData) => {
  return axiosInstance.patch(`/cardprofiles/cardprofiles/${cardProfileId}/`, formData);
};

export const uploadLinkFile = (linkId: number, formData: FormData) => {
  return axiosInstance.patch(`/links/links/${linkId}/`, formData);
};

export const getLink = (linkId: number) => {
  return axiosInstance.get(`/links/links/${linkId}/`);
};

export const updateLink = (linkId: number, value: UpdateLinkInput) => {
  return axiosInstance.patch(`/links/links/${linkId}/`, value);
};

export const getCardProfiles = () => {
  return axiosInstance.get('/cardprofiles/cardprofiles/');
};

export const searchCardProfiles = (query: string) => {
  return axiosInstance.get(`/cardprofiles/search/?query=${query}`);
};

export const getCardProfileData = (cardProfileId: number) => {
  return axiosInstance.get(`/cardprofiles/cardprofiles/${cardProfileId}/`);
};

export const removeCardProfile = (cardProfileId: number) => {
  return axiosInstance.delete(`/cardprofiles/cardprofiles/${cardProfileId}/`);
};

export const createEmptyCardProfile = (userId: number, cardName: string) => {
  return axiosInstance.post(`/cardprofiles/cardprofiles/`, {
    name: cardName,
    user: userId,
  });
};
