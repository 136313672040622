// @flow
import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardProfileInfo } from './CardProfileInfo';
import { UserCardProfileType } from '../OneLink/CardProfileType';

type Props = {
  search?: string;
};
export const NoProfiles = ({ search }: Props) => {
  const { t } = useTranslation();
  const emptyCardProfile: UserCardProfileType = {
    id: 0,
    name: search || '',
    profile_image: '',
    links: [],
    description: '.......................',
    displayname: search || '',
    categories: [],
  };
  return (
    <Container>
      <CardProfileInfo cardProfile={emptyCardProfile}>
        <CardTransparent />
      </CardProfileInfo>
      <Text>{t('NoProfilesText')} </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
`;

const Text = styled.div`
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const CardTransparent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 0.5);
`;
