import * as React from 'react';
import { useReducer } from 'react';
import { AppRoutes } from './routing/Routes';
import { reduceFunc, UserType, UserDispatchContext, UserStateContext } from './helpers/AppContext';
import { getCurrentUser } from './helpers/getCurrentUser';

export const MainApp = () => {
  const defaultState: UserType = {
    user: getCurrentUser(),
  };

  const [State, Dispatch] = useReducer(reduceFunc, defaultState);

  return (
    <UserStateContext.Provider value={State}>
      <UserDispatchContext.Provider value={Dispatch}>
        <AppRoutes />
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};
