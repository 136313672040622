import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import styled from 'styled-components';
import * as React from 'react';
import { Button } from '../Button/Button';

type QRCodeGeneratorModalButtonProps = { cardProfileName: string };

export const QRCodeGeneratorModalButton = (props: QRCodeGeneratorModalButtonProps) => {
  const { t } = useTranslation();
  const linkElement = useRef<HTMLAnchorElement>(null);

  const downloadQrCode = () => {
    const canvas: HTMLCanvasElement | null = document.getElementById('qrCanvas') as HTMLCanvasElement;
    if (canvas && linkElement && linkElement.current) {
      const qrImage = canvas.toDataURL('image/png').replace('image/png', 'image/octet-system');
      linkElement.current.setAttribute('href', qrImage);
    }
  };

  return (
    <>
      <LinkElement ref={linkElement} download={`${props.cardProfileName}-qrCode.png`}>
        <Button onClick={downloadQrCode}>{t('DownloadQrCode')}</Button>
      </LinkElement>
    </>
  );
};

const LinkElement = styled.a``;
