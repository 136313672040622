import * as React from 'react';
import styled from 'styled-components';
import { SVGProps } from 'react';

export const TrashButton = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <SVG
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M28.9922 7.37611L28.2857 5.192C28.0166 4.36015 27.2644 3.80122 26.4143 3.80122H20.4761V1.80746C20.4761 0.810951 19.6905 0 18.7244 0H13.2864C12.3206 0 11.5347 0.810951 11.5347 1.80746V3.80122H5.59671C4.74637 3.80122 3.99418 4.36015 3.72513 5.192L3.01859 7.37611C2.8577 7.87335 2.93924 8.42397 3.23636 8.84921C3.53348 9.27445 4.01419 9.52849 4.5225 9.52849H5.26102L6.88651 30.257C7.00736 31.795 8.27298 33 9.76835 33H22.5723C24.0674 33 25.3333 31.795 25.4539 30.2567L27.0794 9.52849H27.4883C27.9966 9.52849 28.4773 9.27445 28.7745 8.84946C29.0716 8.42422 29.1531 7.87335 28.9922 7.37611V7.37611ZM13.4097 1.93359H18.6011V3.80122H13.4097V1.93359ZM23.585 30.1009C23.5425 30.6424 23.0977 31.0664 22.5723 31.0664H9.76835C9.24296 31.0664 8.79813 30.6424 8.75565 30.1009L7.14212 9.52849H25.1983L23.585 30.1009ZM4.9246 7.59489L5.50394 5.8038C5.51712 5.76251 5.55448 5.73482 5.59671 5.73482H26.4143C26.4566 5.73482 26.4937 5.76251 26.5071 5.8038L27.0865 7.59489H4.9246Z"
        fill="black"
      />
      <path
        d="M20.5945 30.0324C20.6112 30.0334 20.6275 30.0336 20.6441 30.0336C21.1395 30.0336 21.5535 29.6333 21.5794 29.1172L22.4598 11.6892C22.4866 11.1559 22.0892 10.701 21.5723 10.6733C21.054 10.6448 20.6143 11.0552 20.5872 11.5885L19.7071 29.0165C19.6802 29.5497 20.0775 30.0047 20.5945 30.0324V30.0324Z"
        fill="black"
      />
      <path
        d="M10.4731 29.1194C10.5004 29.6348 10.914 30.0336 11.4084 30.0336C11.4255 30.0336 11.443 30.0331 11.4604 30.0321C11.9772 30.0031 12.3735 29.5474 12.3454 29.0142L11.4233 11.5861C11.3952 11.0529 10.9533 10.6443 10.4362 10.6735C9.91937 10.7024 9.52313 11.1581 9.55121 11.6914L10.4731 29.1194Z"
        fill="black"
      />
      <path
        d="M16.0161 30.0336C16.5339 30.0336 16.9536 29.6008 16.9536 29.0668V11.6388C16.9536 11.1048 16.5339 10.672 16.0161 10.672C15.4983 10.672 15.0786 11.1048 15.0786 11.6388V29.0668C15.0786 29.6008 15.4983 30.0336 16.0161 30.0336Z"
        fill="black"
      />
    </SVG>
  );
};

const SVG = styled.svg`
  cursor: pointer;
  :hover path {
    fill: #5b1b1b;
  }
`;
