import React from 'react';
import { Router } from '@reach/router';
import { ROUTES } from './routesConstants';
import { Welcome } from '../pages/Welcome/Welcome';
import { Blog } from '../pages/Blog/Blog';
import { Pricing } from '../pages/Pricing/Pricing';
import { Help } from '../pages/Help/Help';
import { Login } from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import { NotFound } from '../pages/NotFound/NotFound';
import { OneLink } from '../pages/OneLink/OneLink';
import { ProfileContainer } from '../pages/Profile/ProfileContainer';
import { Users } from '../pages/Users/UsersContainer';

export const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <Welcome path={ROUTES.WELCOME} />
      <Blog path={ROUTES.BLOG} />
      <Pricing path={ROUTES.PRICING} />
      <Help path={ROUTES.HELP} />
      <Users path={ROUTES.USERS} />
      <Login path={ROUTES.LOGIN} />
      <Register path={ROUTES.REGISTER} />
      <ProfileContainer path={ROUTES.PROFILE} />
      <NotFound path={ROUTES.NOTFOUND} />
      <OneLink default />
    </Router>
  );
};
