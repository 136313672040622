import React from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { ShareIcon } from '../../assets/icons/ShareIcon';
import { QrCodeGenerator } from './QrCodeGenerator';

type Props = {
  url: string;
};
export const CopyLinkElement = (props: Props) => {
  const { url } = props;
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const URL = `${process.env.HOST}${url}`;

  const onCopy = () => {
    const el = document.createElement('textarea');
    el.value = URL;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    addToast(t('CopiedToClipboard'), { appearance: 'info' });
  };

  return (
    <Container>
      <Url>
        <span>{process.env.HOST}</span>
        <Target>{url}</Target>
      </Url>
      <IconsContainer>
        <ShareIconContainer onClick={onCopy}>
          <ShareIcon />
        </ShareIconContainer>
        <QrCodeGenerator url={URL} cardName={url} />
      </IconsContainer>
    </Container>
  );
};

const Target = styled.span`
  text-decoration-line: underline;
  color: #4a89c7;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 37px;
  background: #f4f5f9;
  border-radius: 18.5px;
  margin: 25px 0;
  padding: 0 18px;
`;

const Url = styled.div`
  flex-grow: 1;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
`;

const ShareIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: flex;
`;
