import * as React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Form, Input } from '../../components/FormTheme';
import { CardProfileType } from '../OneLink/CardProfileType';
import { shallowEqual } from '../../utils/shallowEqual';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';

type Props = {
  cardProfile: CardProfileType;
  save: (values: SettingsDataFormValues) => Promise<void>;
  removeProfile: () => void;
};

export type SettingsDataFormValues = {
  address: string;
  categories: any[];
  name: string;
};

export const Settings = (props: Props) => {
  const { cardProfile, save } = props;
  const { t } = useTranslation();
  const initialValues: SettingsDataFormValues = {
    address: cardProfile.address,
    categories: cardProfile.categories,
    name: cardProfile.name,
  };

  const onSubmit = (values: SettingsDataFormValues) => {
    if (!shallowEqual(initialValues, values)) {
      save(values).then(() => {
        formik.setSubmitting(false);
      });
    } else {
      formik.setSubmitting(false);
    }
  };

  const saveOnBlur = (e: React.FocusEvent) => {
    formik.handleBlur(e);
    if (!shallowEqual(initialValues, formik.values)) {
      formik.setSubmitting(true);
      save(formik.values).then(() => {
        formik.setSubmitting(false);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Container>
      <Form>
        <span>{t('Tabs.Settings.NameLabel')}</span>
        <StyledInput
          onChange={formik.handleChange}
          name="name"
          type="text"
          value={formik.values.name}
          onBlur={saveOnBlur}
          placeholder="..."
          disabled={formik.isSubmitting}
        />
        <span>{t('Tabs.Settings.AddressLabel')}</span>
        <StyledInput
          onChange={formik.handleChange}
          name="address"
          type="text"
          value={formik.values.address}
          onBlur={saveOnBlur}
          placeholder="..."
          disabled={formik.isSubmitting}
        />
        {formik.isSubmitting && <CentredSpinner />}
        <input
          hidden
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        />
      </Form>
      <RemoveCardProfile onClick={props.removeProfile}>{t('removeCardProfile')}</RemoveCardProfile>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  text-align: center;
  margin-bottom: 10px;
`;

const RemoveCardProfile = styled.span`
  text-align: center;
  :hover {
    color: red;
  }
  cursor: pointer;
`;
