import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import * as React from 'react';
import logo_mini from '../../assets/images/logo_mini.svg';
import { Modal } from '../Modal/Modal';
import { QRCodeGeneratorModalButton } from './QRCodeGeneratorModalButton';

type QRCodeGeneratorModalProps = {
  open: boolean;
  closeModal: () => void;
  urlToQr: string;
  cardName: string;
};

export const QRCodeGeneratorModal = ({ open, closeModal, urlToQr, cardName }: QRCodeGeneratorModalProps) => {
  const { t } = useTranslation();
  const closeButton = (): JSX.Element => <CloseButton onClick={() => closeModal()}>X</CloseButton>;

  return open ? (
    <Modal
      headerButton={closeButton()}
      size="small"
      footerContent={<QRCodeGeneratorModalButton cardProfileName={cardName} />}
      id="qrCodeGenerator"
      title={t('YourQRCode')}
    >
      <QRCode
        id="qrCanvas"
        value={urlToQr}
        size={200}
        bgColor="rgb(0,0,0,0)"
        fgColor="#000000"
        level="Q"
        includeMargin={false}
        renderAs="canvas"
        imageSettings={{
          src: logo_mini,
          height: 40,
          width: 40,
          excavate: true,
        }}
      />
    </Modal>
  ) : (
    <></>
  );
};

const CloseButton = styled.div`
  :hover {
    color: grey;
  }
  cursor: pointer;
`;
