import * as React from 'react';

type Props = {
  className?: string;
};
export const EditIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.5869 2.33636C44.4715 -0.778787 39.4206 -0.778787 36.3052 2.33636L4.67862 33.9601C4.46188 34.1768 4.30538 34.4455 4.22367 34.7404L0.0646884 49.7539C-0.106352 50.3695 0.0674581 51.0287 0.518949 51.4808C0.971133 51.9323 1.63037 52.1061 2.24597 51.9357L17.2608 47.7764C17.5558 47.6947 17.8245 47.5382 18.0412 47.3215L33.8541 31.5093L49.6671 15.6971C52.7776 12.5799 52.7776 7.53359 49.6671 4.41636L47.5869 2.33636ZM8.54122 35.1123L34.4251 9.22999L42.7728 17.577L16.8883 43.4592L8.54122 35.1123ZM6.87375 38.458L13.5429 45.1273L4.31784 47.6829L6.87375 38.458ZM47.1603 13.1906L45.2803 15.0705L36.9318 6.72278L38.8126 4.84288C40.5431 3.11255 43.349 3.11255 45.0795 4.84288L47.1603 6.92288C48.888 8.65529 48.888 11.4589 47.1603 13.1906Z"
        fill="#141415"
      />
    </svg>
  );
};
