// @flow
import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { PhoneIcon } from '../../assets/icons/PhoneIcon';
import { Modal } from '../Modal/Modal';

type Props = {
  footerContent: JSX.Element;
  children?: JSX.Element;
};
export const PreviewButton = ({ children, footerContent }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Container onClick={() => setOpen(true)}>
        <PhoneIcon /> <Text>PREVIEW</Text>
      </Container>
      {open ? (
        <StyledModal
          id="cardProfilePreview"
          footerContent={footerContent}
          title=""
          headerButton={<Button onClick={() => setOpen(false)}>X</Button>}
        >
          <Centered>{children}</Centered>
        </StyledModal>
      ) : (
        <> </>
      )}
    </>
  );
};

const StyledModal = styled(Modal)`
  .box-dialog {
    position: fixed;
    height: 100%;
  }

  .box-content {
    height: calc(100% - 200px);
    overflow: auto;
  }
`;

const Centered = styled.div`
  width: 100%;
`;

const Button = styled.div``;

const Container = styled.div`
  background: #0570f9;
  border-radius: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background: #2d8cf3;
  }
`;

const Text = styled.span`
  color: #fff;
`;
