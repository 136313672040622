import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { PhoneEmulatorSvg } from './PhoneEmulatorSvg';

type Props = {
  children: JSX.Element | string;
  className?: string;
};
export const PhoneEmulator = ({ children, className }: Props) => {
  const phoneContent = useRef<HTMLDivElement>(null);
  const phoneContainer = useRef<HTMLDivElement>(null);
  const phoneEmulator = useRef<SVGSVGElement>(null);

  const resizePhoneContent = () => {
    if (phoneEmulator.current && phoneContent.current && phoneContainer.current) {
      const width = phoneEmulator.current.width.animVal.value;
      const height = phoneEmulator.current.height.animVal.value;

      const smallFontSize = 'font-size: 9px';
      const setSmallFont = width < 250;

      if (width === 0 && height === 0) {
        setTimeout(resizePhoneContent, 100);
      } else {
        phoneContent.current.setAttribute(
          'style',
          `width: ${width - 50}px; height: ${height - 65}px; ${setSmallFont && smallFontSize}`,
        );
        phoneContainer.current.setAttribute('style', `width: ${width * 1.2}px; height: ${height}px`);
      }
    }
  };

  useEffect(() => {
    resizePhoneContent();
    window.addEventListener('resize', resizePhoneContent);
    return () => {
      window.removeEventListener('resize', resizePhoneContent);
    };
  }, []);

  return (
    <PhoneContainer style={{ width: '500px' }} ref={phoneContainer} className={className}>
      <StyledPhoneEmulator ref={phoneEmulator} />
      <PhoneContent style={{ display: 'none' }} ref={phoneContent}>
        {children}
      </PhoneContent>
    </PhoneContainer>
  );
};

const PhoneContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const StyledPhoneEmulator = styled(PhoneEmulatorSvg)`
  position: absolute;
  height: 75vh;
  z-index: -1;
`;

const PhoneContent = styled.div`
  position: absolute;
  font-size: 12px;
  width: inherit;
  margin: 45px 25px 20px 20px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar {
    padding-left: 2px;
    position: absolute;
    width: 2px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`;
