// @flow
import * as React from 'react';
import styled from 'styled-components';
import { LinkSet, UserCardProfileType } from '../OneLink/CardProfileType';
import defaultProfileImage from '../../assets/images/default-profile-image.svg';
import { sortByPriority } from '../../utils/sortByPriority';

type Props = {
  cardProfile: UserCardProfileType;
  elemNumber?: number;
  children?: JSX.Element;
};
export const CardProfileInfo = (props: Props) => {
  const { cardProfile, elemNumber } = props;
  return (
    <RootContainer>
      <Container>
        {elemNumber && <NumberContainer>#{elemNumber}</NumberContainer>}
        <ProfileImage src={cardProfile.profile_image || defaultProfileImage} alt="profileImage" />
        <DisplayName>{cardProfile.displayname}</DisplayName>
        <Description>{getShortVersion(cardProfile.description)}</Description>
        <Links>{getThreeMaxPriorityLink(cardProfile.links)} </Links>
      </Container>
      {props.children}
    </RootContainer>
  );
};

const getThreeMaxPriorityLink = (links: LinkSet[]) => {
  const linksToReturn = [...links].sort(sortByPriority).slice(0, 3);

  return linksToReturn.map((link) => (
    <IconContainer key={link.id} href={link.target} target="_blank">
      <Icon src={link.thumbnail} alt="social_icon" />
    </IconContainer>
  ));
};

const getShortVersion = (text: string) => {
  if (!text) return '';
  const splittedByNewLine = text.split(/\n/);

  const newLine = String.fromCharCode(13, 10);
  let shortenText = '';
  switch (splittedByNewLine.length) {
    case 1:
      shortenText = `${splittedByNewLine[0]}`;
      break;
    default:
      shortenText = `${splittedByNewLine[0]}${newLine}${splittedByNewLine[1]}`;
  }

  shortenText = `${shortenText.substr(0, 74)}`;

  return text.length > 74 ? `${shortenText}...` : shortenText;
};

const Links = styled.div`
  margin-top: 15px;
  display: flex;
`;

const RootContainer = styled.div`
  position: relative;
  width: 307px;
  height: 241px;
`;

const Container = styled.div`
  position: relative;
  background: #f2f3f8;
  border: 1px solid #bfc2c7;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px;
`;

const ProfileImage = styled.img`
  width: 55px;
  height: 55px;

  border-radius: 50%;
`;

const DisplayName = styled.div`
  margin-top: 8px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 15px;
  height: 30px;
  text-align: center;
  white-space: pre-wrap;
`;

const IconContainer = styled.a`
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  margin: 0 4px;
`;

const Icon = styled.img`
  width: 28px;
`;

const NumberContainer = styled.div`
  position: absolute;
  left: 15px;
  top: 10px;
  font-size: 20px;
  color: #bfc2c7;
`;
