import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import profileLoop from '../../assets/animations/profileLoop.mp4';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';

export const LoopVideo = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <VideoContainer>
      {isLoading && <CentredSpinner />}
      <StyledVideo muted autoPlay loop playsInline onLoadedMetadata={() => setIsLoading(false)}>
        <source src={profileLoop} />
      </StyledVideo>
    </VideoContainer>
  );
};

const StyledVideo = styled.video`
  width: 100%;

  @media (max-width: 400px) {
    width: 300px;
  }
`;

const VideoContainer = styled.div`
  width: 360px;
  @media (max-width: 400px) {
    margin: 2rem 0 0 0;
  }
`;
