// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { welcomeAnimatedElements } from './WelcomeAnimatedElements';
import { Button } from '../../components/Button/Button';
import { ROUTES } from '../../routing/routesConstants';
import { Carousel } from '../../components/Carousel/Carousel';

export const WelcomeHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Welcome>{t('WelcomePage.Welcome')}</Welcome>
      <Carousel elements={welcomeAnimatedElements} staticText={t('WelcomePage.Animated')} />
      <Info>{t('WelcomePage.Info')}</Info>
      <GetStartedContainer>
        <Button
          onClick={() => {
            navigate(ROUTES.REGISTER);
          }}
        >
          {t('WelcomePage.Button')}
        </Button>
      </GetStartedContainer>
    </>
  );
};

const Welcome = styled.div`
  margin-top: 1rem;
`;

const GetStartedContainer = styled.div`
  margin: 15px 0;
`;

const Info = styled.div`
  margin-top: -20px;

  @media (max-width: 450px) {
    text-align: center;
  }
`;
