import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import headerAnimation from '../../assets/animations/headerAnim.mp4';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';

export const HeaderPhoneVideo = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <VideoContainer>
      {isLoading && <CentredSpinner />}
      <StyledVideo muted autoPlay loop playsInline onLoadedMetadata={() => setIsLoading(false)}>
        <source src={headerAnimation} />
      </StyledVideo>
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  margin-top: 1rem;
`;

const StyledVideo = styled.video`
  width: 100%;

  @media (max-width: 400px) {
    width: 300px;
  }
`;
