import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { EditLinkOnMobileModal } from './EditLinkOnMobileModal';
import { EditIcon } from '../../assets/icons/EditIcon';
import { EditSocialLinkOnMobileModal } from './EditSocialLinkOnMobileModal';

type Props = {
  name: string;
  url: string;
  icon?: string;
  onSuccess: (data: { name: string; url: string }) => void;
};
export const EditLinkInModal = ({ name, url, icon, onSuccess }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const editLink = (data: { name: string; url: string }) => {
    setModalOpen(false);
    onSuccess(data);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <EditLinkOnMobileButton onClick={() => setModalOpen(true)}>
        <StyledEditIcon />
      </EditLinkOnMobileButton>
      {!icon ? (
        <EditLinkOnMobileModal open={modalOpen} onSuccess={editLink} onClose={closeModal} name={name} target={url} />
      ) : (
        <EditSocialLinkOnMobileModal
          open={modalOpen}
          onClose={closeModal}
          onSuccess={editLink}
          name={name}
          target={url}
          icon={icon}
        />
      )}
    </>
  );
};

const StyledEditIcon = styled(EditIcon)`
  height: 25px;
  width: 100%;
  display: flex;
  padding-bottom: 10px;
`;

const EditLinkOnMobileButton = styled.div`
  cursor: pointer;
`;
