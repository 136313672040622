import cookie from 'react-cookies';
import jwt_decode from 'jwt-decode';
import { LocalStorageUser } from '../types/LocalStorageUser';

export const ACCESS_TOKEN_NAME = 'jwt';
export const REFRESH_TOKEN_NAME = 'jid';

export const setCurrentUser = (user: LocalStorageUser) => {
  const access: { exp: number } = jwt_decode(user.access);
  const refresh: { exp: number } = jwt_decode(user.refresh);
  cookie.save(ACCESS_TOKEN_NAME, user.access, { path: '/', expires: new Date(access.exp * 1000) });
  cookie.save(REFRESH_TOKEN_NAME, user.refresh, { path: '/', expires: new Date(refresh.exp * 1000) });
};
