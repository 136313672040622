import * as React from 'react';
import styled, { css } from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DraggableLinkBox, LinkBox } from './LinkBox';
import { LinkSet, LinksTab } from '../OneLink/CardProfileType';
import { createNewLink, removeLinkQuery, updateLinkPatch } from '../../helpers/endpoints';
import { convertToUpdateLinkInput } from '../../types/UpdateLinkInput';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';
import { AddButton } from '../../assets/icons/AddButton';
import { convertToCreateLinkInput } from '../../types/CreateLinkInput';
import { LinkBoxFromValues } from './types/LinkBoxFromValues';
import { EditLinkOnMobileModal } from './EditLinkOnMobileModal';
import useWindowSize from '../../hooks/useWindowSize';

type LinkProps = {
  links: LinkSet[];
  cardProfileId: number;
  updateLinks: (links: LinkSet[], toDelete?: LinkSet, toAdd?: LinkSet) => Promise<void>;
  className?: string;
};
export const Links = (props: LinkProps) => {
  const { links, updateLinks, cardProfileId } = props;
  const linksTab = LinksTab.LINK;
  const width = useWindowSize();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addNewMode, setAddNewMode] = useState(false);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const newLinks = Array.from(links);
    const linkToMove = { ...newLinks[source.index] };
    newLinks.splice(source.index, 1);
    newLinks.splice(destination.index, 0, linkToMove);

    for (let i = 0; i < newLinks.length; i += 1) {
      newLinks[i].priority = i;
    }

    setIsSubmitting(true);
    await updateLinks([...newLinks]);
    setIsSubmitting(false);
  };

  const addNew = (values: LinkBoxFromValues) => {
    return new Promise<void>((resolve) => {
      return createNewLink(convertToCreateLinkInput(values, cardProfileId, linksTab)).then(async (response) => {
        const newLink: LinkSet = response.data;
        const linksCopy = Array.from(links);
        linksCopy.unshift(newLink);

        for (let i = 0; i < linksCopy.length; i += 1) {
          linksCopy[i].priority = i;
        }

        setIsSubmitting(true);
        await updateLinks(linksCopy, undefined, newLink);
        setIsSubmitting(false);
        setAddNewMode(false);
        resolve();
      });
    });
  };

  const cancelAdding = () => {
    return new Promise<void>((resolve) => {
      setAddNewMode(false);
      resolve();
    });
  };

  const updateLink = (values: LinkBoxFromValues, id: number) => {
    return updateLinkPatch(id, convertToUpdateLinkInput(values)).then((response) => {
      const linkSet: LinkSet = response.data;
      updateLinks([linkSet]);
    });
  };

  const updateLinkImage = async (link: LinkSet) => {
    const linksCopy = Array.from(links);
    const linkInArray = linksCopy.find((el) => el.id === link.id);
    if (linkInArray) {
      const linkIndex = linksCopy.indexOf(linkInArray);
      linksCopy.splice(linkIndex, 1, link);

      setIsSubmitting(true);
      await updateLinks(linksCopy);
      setIsSubmitting(false);
      setAddNewMode(false);
    }
    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  const addNewInMobile = (data: { name: string; url: string }) => {
    addNew({ name: data.name, target: data.url, active: true, asIcon: true }).then(() => setAddNewMode(false));
  };

  const getComponentForAddLink = () => {
    return width <= 768 ? (
      <EditLinkOnMobileModal
        open={addNewMode}
        onClose={() => setAddNewMode(false)}
        name=""
        target=""
        externalModalTitle={t('AddLinksTitle')}
        onSuccess={addNewInMobile}
      />
    ) : (
      <LinkBox
        name=""
        id={99}
        url=""
        icon={undefined}
        addingMode
        index={0}
        active
        asIcon
        save={addNew}
        onRemove={cancelAdding}
      />
    );
  };

  const removeLink = async (id: number) => {
    return removeLinkQuery(id)
      .then(async () => {
        const linkToDelete = links.find((l) => l.id === id);
        if (linkToDelete) {
          const index = links.indexOf(linkToDelete);
          const linksArray = [...links];
          linksArray.splice(index, 1);

          for (let i = 0; i < linksArray.length; i += 1) {
            linksArray[i].priority = i;
          }

          setIsSubmitting(true);
          await updateLinks(linksArray, linkToDelete);
          setIsSubmitting(false);
        }
      })
      .catch(() => {
        console.log('Error occurred during remove link');
      });
  };

  return (
    <Container className={props.className}>
      {isSubmitting && (
        <SpinnerBackground>
          <CentredSpinner />
        </SpinnerBackground>
      )}
      <AddContainer>
        {addNewMode ? (
          getComponentForAddLink()
        ) : (
          <AddNewButton
            onClick={() => {
              setAddNewMode(true);
            }}
          >
            <AddButton />
          </AddNewButton>
        )}
      </AddContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${linksTab}`}>
          {(provided, snapshot) => (
            <DropContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {links.map((link, index) => (
                <DraggableLinkBox
                  key={link.id}
                  icon={link.thumbnail}
                  name={link.name}
                  url={link.target}
                  id={link.id}
                  index={index}
                  active={link.is_active}
                  asIcon={link.is_icon}
                  save={updateLink}
                  onRemove={removeLink}
                  updateLinkImage={updateLinkImage}
                />
              ))}
              {provided.placeholder}
            </DropContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

const AddContainer = styled.div`
  padding: 5px;
`;

const AddNewButton = styled.div`
  margin-top: 5px;
  border-radius: 10px;
  border: 1px solid #dee3ed;
  padding: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #f2f3f8;

  cursor: pointer;

  :hover svg circle {
    fill: #2d8cf3;
  }
`;

const SpinnerBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 2;
`;

const Container = styled.div`
  position: relative;
  max-width: 820px;
  margin: auto;
`;

const DropContainer = styled.div<{ isDraggingOver: boolean }>`
  overflow: auto;
  height: calc(100vh - 315px);
  padding: 5px;
  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      padding: 4px;
      border: 0.5px dotted lightgray;
      border-radius: 10px;
    `}
`;
