// @flow
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ROUTES } from '../../routing/routesConstants';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { UserPageContainer } from './UserPageContainer';

const verifyUserURI = (uri: string | undefined) => {
  if (!uri) return true;

  const backslashCount = (uri.match(new RegExp('/', 'g')) || []).length;
  if (backslashCount < 1 || backslashCount > 2) return true;

  return false;
};

export const OneLink: FunctionComponent<RouteComponentProps> = (props) => {
  const { uri } = props;
  const [needRedirect, setNeedRedirect] = useState(verifyUserURI(uri));
  const navigate = useNavigate();

  useEffect(() => {
    if (needRedirect) {
      navigate(ROUTES.NOTFOUND);
    }
  }, [needRedirect]);

  if (needRedirect) {
    return <AbsoluteSpinner />;
  }

  const urlParams = uri?.replace('/', '').split('/');
  if (!urlParams || urlParams.length === 0) {
    setNeedRedirect(true);
    return <AbsoluteSpinner />;
  }

  const cardName = urlParams[0];
  return <UserPageContainer cardname={cardName} needRedirect={setNeedRedirect} />;
};
