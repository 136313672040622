import * as React from 'react';
import jwt_decode from 'jwt-decode';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { useUserState } from '../../hooks/useUserState';
import { LayoutDefault } from '../../components/Layouts/LayoutDefault';
import { ROUTES } from '../../routing/routesConstants';
import { useUsersQuery } from '../../hooks/queriesHooks';
import { CardProfileSelector } from './CardProfileSelector';
import { createEmptyCardProfile } from '../../helpers/endpoints';

export const ProfileContainer: FunctionComponent<RouteComponentProps> = () => {
  const { user } = useUserState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(ROUTES.LOGIN);
    }
  }, [user]);

  if (!user) {
    return withLayoutDefault(<AbsoluteSpinner />);
  }

  const decode: { user_id: string } = jwt_decode(user);
  const [data, loading, error, setData] = useUsersQuery(decode.user_id);

  useEffect(() => {
    const createCardProfile = async () => {
      if (data && data.cardprofile_set.length === 0) {
        const response = await createEmptyCardProfile(data.id, data.username);
        if (response && response.data) {
          setData({ ...data, cardprofile_set: [...data.cardprofile_set, response.data] });
        }
      }
    };

    createCardProfile();
  }, [data]);

  if (error) return withLayoutDefault(<div>Something wrong!</div>);
  if (!data || loading) return withLayoutDefault(<AbsoluteSpinner />);

  if (data.cardprofile_set.length === 0) return withLayoutDefault(<AbsoluteSpinner />);

  return withLayoutDefault(<CardProfileSelector cardProfiles={data.cardprofile_set} userId={data.id} />);
};

const withLayoutDefault = (children: JSX.Element) => {
  return (
    <LayoutDefault>
      <Container>{children}</Container>
    </LayoutDefault>
  );
};

const Container = styled.div`
  padding: 0 20px;
`;
