// @flow
import * as React from 'react';
import styled from 'styled-components';
import { SVGProps } from 'react';

export const CameraIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <SVGContainer>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M15.5276 10.3361C14.7066 10.3361 13.9092 10.4953 13.1576 10.8092C12.9088 10.9132 12.7914 11.1991 12.8953 11.448C12.9992 11.6969 13.2852 11.8141 13.534 11.7103C14.1658 11.4464 14.8365 11.3126 15.5276 11.3126C18.3816 11.3126 20.7034 13.6345 20.7034 16.4884C20.7034 19.3423 18.3816 21.6642 15.5276 21.6642C12.6736 21.6642 10.3518 19.3423 10.3518 16.4884C10.3518 15.7984 10.4852 15.1286 10.7483 14.4977C10.8522 14.2488 10.7345 13.9629 10.4856 13.8591C10.2369 13.7554 9.95083 13.8729 9.84702 14.1218C9.53398 14.8724 9.37524 15.6686 9.37524 16.4884C9.37524 19.8809 12.1352 22.6408 15.5276 22.6408C18.92 22.6408 21.68 19.8809 21.68 16.4884C21.68 13.096 18.92 10.3361 15.5276 10.3361Z"
          fill="#838587"
        />
        <path
          d="M15.5276 20.4923C17.7354 20.4923 19.5315 18.6962 19.5315 16.4884C19.5315 14.2806 17.7354 12.4845 15.5276 12.4845C13.3198 12.4845 11.5237 14.2806 11.5237 16.4884C11.5237 18.6962 13.3199 20.4923 15.5276 20.4923ZM15.5276 13.4611C17.1969 13.4611 18.5549 14.8192 18.5549 16.4884C18.5549 18.1577 17.1969 19.5158 15.5276 19.5158C13.8583 19.5158 12.5002 18.1577 12.5002 16.4884C12.5002 14.8191 13.8583 13.4611 15.5276 13.4611Z"
          fill="#838587"
        />
        <path
          d="M22.5237 7.99232H21.0383L19.3335 5.1072C19.2456 4.95852 19.0858 4.86731 18.9131 4.86731H12.1423C11.9696 4.86731 11.8097 4.95852 11.7219 5.1072L10.0171 7.99232H7.22658V7.40638C7.22658 6.43709 6.43805 5.64856 5.46876 5.64856C4.49947 5.64856 3.71094 6.43709 3.71094 7.40638V7.99232H2.47637C1.11089 7.99232 0 9.10321 0 10.4687V22.5081C0 23.8736 1.11089 24.9845 2.47637 24.9845H22.5236C23.8891 24.9845 25 23.8736 25 22.5081V10.4687C25 9.10321 23.8891 7.99232 22.5237 7.99232ZM12.4209 5.84387H18.6345L19.904 7.99232H11.1513L12.4209 5.84387ZM4.68751 7.40638C4.68751 6.97561 5.038 6.62513 5.46876 6.62513C5.89952 6.62513 6.25001 6.97561 6.25001 7.40638V7.99232H4.68751V7.40638ZM24.0235 22.5081C24.0235 23.3352 23.3507 24.008 22.5237 24.008H2.47637C1.64937 24.008 0.976564 23.3351 0.976564 22.5081V10.4687C0.976564 9.64168 1.64937 8.96888 2.47637 8.96888H22.5236C23.3506 8.96888 24.0234 9.64168 24.0234 10.4687V22.5081H24.0235Z"
          fill="#838587"
        />
        <path
          d="M15.5276 15.5363C16.0526 15.5363 16.4797 15.9634 16.4797 16.4884C16.4797 16.7581 16.6983 16.9767 16.968 16.9767C17.2377 16.9767 17.4563 16.7581 17.4563 16.4884C17.4563 15.4249 16.5911 14.5597 15.5276 14.5597C15.2579 14.5597 15.0393 14.7783 15.0393 15.048C15.0393 15.3176 15.2579 15.5363 15.5276 15.5363Z"
          fill="#838587"
        />
        <path
          d="M15.5276 3.58267C15.7973 3.58267 16.0159 3.36402 16.0159 3.09439V0.488282C16.0159 0.218653 15.7973 0 15.5276 0C15.2579 0 15.0393 0.218604 15.0393 0.488282V3.09439C15.0393 3.36407 15.2579 3.58267 15.5276 3.58267Z"
          fill="#838587"
        />
        <path
          d="M17.8512 3.5827C17.9761 3.5827 18.1011 3.53499 18.1964 3.43968L19.988 1.64812C20.1787 1.45745 20.1787 1.14827 19.988 0.957544C19.7973 0.766919 19.4881 0.766919 19.2974 0.957544L17.5059 2.74915C17.3152 2.93982 17.3152 3.249 17.5059 3.43973C17.6013 3.53504 17.7262 3.5827 17.8512 3.5827V3.5827Z"
          fill="#838587"
        />
        <path
          d="M12.859 3.43978C12.9543 3.53509 13.0793 3.5828 13.2042 3.5828C13.3292 3.5828 13.4542 3.53509 13.5495 3.43978C13.7402 3.24911 13.7402 2.93993 13.5496 2.7492L11.758 0.957648C11.5673 0.767071 11.2581 0.767023 11.0675 0.957648C10.8768 1.14832 10.8767 1.4575 11.0674 1.64823L12.859 3.43978Z"
          fill="#838587"
        />
        <path
          d="M6.73829 11.9962H4.19922C3.92954 11.9962 3.71094 12.2149 3.71094 12.4845C3.71094 12.7542 3.92954 12.9728 4.19922 12.9728H6.73829C7.00797 12.9728 7.22657 12.7542 7.22657 12.4845C7.22657 12.2148 7.00797 11.9962 6.73829 11.9962Z"
          fill="#838587"
        />
        <path
          d="M11.5215 12.9728C11.6499 12.9728 11.7759 12.9205 11.8667 12.8297C11.9575 12.7389 12.0098 12.6134 12.0098 12.4845C12.0098 12.3561 11.9575 12.2301 11.8667 12.1393C11.7759 12.0485 11.6499 11.9962 11.5215 11.9962C11.3931 11.9962 11.2671 12.0485 11.1763 12.1393C11.085 12.2301 11.0332 12.3561 11.0332 12.4845C11.0332 12.6129 11.085 12.7389 11.1763 12.8297C11.2671 12.9205 11.3926 12.9728 11.5215 12.9728Z"
          fill="#838587"
        />
      </svg>
    </SVGContainer>
  );
};

const SVGContainer = styled.div`
  svg {
    cursor: pointer;
    :hover path {
      fill: #5b1b1b;
    }
  }
`;
