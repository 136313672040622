import { convertToUpdateLinkInput, UpdateLinkInput } from './UpdateLinkInput';
import { LinksTab } from '../pages/OneLink/CardProfileType';
import { LinkBoxFromValues } from '../pages/Profile/types/LinkBoxFromValues';

export type CreateLinkInput = UpdateLinkInput & {
  cardprofile: number;
  linkstab: number;
};

export const convertToCreateLinkInput = (values: LinkBoxFromValues, cardProfileId: number, linksTab: LinksTab) => {
  const convertValues = convertToUpdateLinkInput(values);

  switch (linksTab) {
    default:
    case LinksTab.LINK:
      convertValues.is_icon = false;
      break;
    case LinksTab.SOCIAL_LINK:
      convertValues.is_icon = true;
      break;
  }

  const result: CreateLinkInput = { ...convertValues, cardprofile: cardProfileId, linkstab: linksTab };
  return result;
};
