export default {
  Youtube: 'https://youtube.com/',
  Instagram: 'https://instagram.com/',
  TikTok: 'https://tiktok.com/@',
  Facebook: 'https://facebook.com/',
  Twitter: 'https://twitter.com/',
  Twitch: 'https://twitch.tv/',
  Spotify: 'https://open.spotify.com/',
  Snapchat: 'https://snapchat.com/add/',
  LinkedIn: 'https://linkedin.com/in/',
  Pinterest: 'https://pinterest.com/',
  Soundcloud: 'https://soundcloud.com/',
  Discord: 'https://discord.gg/',
  Skype: 'https://join.skype.com/',
  Steam: 'https://steamcommunity.com/',
  Github: 'https://github.com/',
  StackOverflow: 'https://stackoverflow.com/',
  Codepen: 'https://codepen.io/',
  GoodReads: 'https://www.goodreads.com/',
  WhatsApp: 'https://wa.me/',
  Disqus: 'https://disqus.com/by/',
  Medium: 'https://medium.com/',
  Messenger: 'https://m.me/',
  Trello: 'https://trello.com/',
  Vimeo: 'https://vimeo.com/',
  Tumblr: 'https://www.tumblr.com/',
};
