// @flow
import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTopQuery } from '../../hooks/queriesHooks';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { UsersComponent } from './UsersComponent';
import { withLayoutDefault } from '../../components/Layouts/withLayoutDefault';

const UsersContainer = (props: RouteComponentProps) => {
  const [data, loading, error] = useTopQuery();

  if (error) return <div>Something wrong!</div>;
  if (!data || loading) return <AbsoluteSpinner />;

  return <UsersComponent topCardProfiles={data} />;
};

export const Users = (props: RouteComponentProps) => withLayoutDefault(<UsersContainer {...props} />);
