// @flow
import * as React from 'react';
import { KeyboardEvent, ChangeEvent, useState } from 'react';
import { useNavigate } from '@reach/router';
import { ROUTES } from '../../routing/routesConstants';
import { NewProfileNameInput } from '../../components/NewProfileNameInput/NewProfileNameInput';

export const NewProfileInput = (props: { className?: string }) => {
  const [cardName, setProfileName] = useState<string>('');
  const navigate = useNavigate();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const profileName = event.target.value;
    setProfileName(profileName);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigate(ROUTES.REGISTER, { state: { username: cardName } });
    }
  };

  return (
    <NewProfileNameInput className={props.className} onChange={onChange} onKeyPress={onKeyPress} cardName={cardName} />
  );
};
