import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Dots } from '../../assets/icons/Dots';
import { shallowEqual } from '../../utils/shallowEqual';
import { ToggleButton } from '../../components/Toggle/ToggleButton';
import { SmallSpinner } from '../../components/Spinner/SmallSpinner';
import { RemoveButton } from '../../assets/icons/RemoveButton';
import { AcceptButton } from '../../assets/icons/AcceptButton';
import { TrashButton } from '../../assets/icons/TrashButton';
import { CameraIcon } from '../../assets/icons/CameraIcon';
import { FileUploader } from '../../components/FileUploader/FileUploader';
import { RemoveProfileImage } from '../../assets/icons/RemoveProfileImage';
import { EditLinkImage } from '../../assets/icons/EditLinkImage';
import { getLink, updateLink, uploadLinkFile } from '../../helpers/endpoints';
import { LinkSet } from '../OneLink/CardProfileType';
import { LinkBoxFromValues } from './types/LinkBoxFromValues';
import useWindowSize from '../../hooks/useWindowSize';
import { EditLinkInModal } from './EditLinkInModal';

type LinkBoxProps = {
  id: number;
  name: string;
  url: string;
  icon: string | undefined;
  index: number;
  active: boolean;
  asIcon: boolean;
  addingMode?: boolean;
  save: (values: LinkBoxFromValues, id: number) => Promise<void>;
  onRemove: (id: number) => Promise<void>;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  updateLinkImage?: (link: LinkSet) => Promise<void>;
};

export const LinkBox = (props: LinkBoxProps) => {
  const {
    id,
    name,
    url,
    active,
    asIcon,
    save,
    icon,
    onRemove,
    provided,
    snapshot,
    addingMode,
    updateLinkImage,
  } = props;
  const editLinkImageSvg = encodeURIComponent(renderToStaticMarkup(<EditLinkImage />));

  const { t } = useTranslation();
  const initialValues: LinkBoxFromValues = { name, target: url, active, asIcon };
  const fileUploader = useRef<HTMLInputElement>(null);

  const width = useWindowSize();

  const onSubmit = (values: LinkBoxFromValues) => {
    if (!shallowEqual(initialValues, values) && formik.isValid && !addingMode) {
      save(values, id).then(() => {
        formik.setSubmitting(false);
      });
    } else if (addingMode) {
      addNewLink();
    } else {
      formik.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('RequiredField')),
    target: Yup.string().required(t('RequiredField')),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialStatus: 'NEW',
  });

  const uploadProfileImage = async (file: File) => {
    if (updateLinkImage) {
      const formData = new FormData();
      formData.append('thumbnail', file, file.name);

      formik.setSubmitting(true);
      await uploadLinkFile(id, formData);
      const result = await getLink(id);
      const updatedLinkSet: LinkSet = result.data;
      await updateLinkImage(updatedLinkSet);

      return new Promise<void>((resolve) => {
        resolve();
        formik.setSubmitting(false);
      });
    }
    return new Promise<void>((resolve) => resolve());
  };

  const removeProfileImage = async () => {
    if (updateLinkImage) {
      formik.setSubmitting(true);
      await updateLink(id, { id, thumbnail: null });
      const result = await getLink(id);
      const updatedLinkSet: LinkSet = result.data;
      await updateLinkImage(updatedLinkSet);

      return new Promise<void>((resolve) => {
        resolve();
        formik.setSubmitting(false);
      });
    }

    return new Promise<void>((resolve) => resolve());
  };

  const editProfileImage = () => {
    if (fileUploader.current) {
      fileUploader.current.click();
    }
  };

  const addNewLink = async () => {
    if (formik.isValid && addingMode && formik.status === '') {
      formik.setSubmitting(true);
      save(formik.values, 0);
    }
  };

  const saveOnBlur = (e: React.FocusEvent) => {
    formik.handleBlur(e);
    if (!shallowEqual(initialValues, formik.values) && formik.isValid && !addingMode) {
      formik.setSubmitting(true);
      save(formik.values, id).then(() => {
        formik.setSubmitting(false);
      });
    }
  };

  const removeLink = async () => {
    formik.setSubmitting(true);
    await onRemove(id);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setStatus('');
    formik.handleChange(e);
  };

  const saveInModal = (data: { name: string; url: string }) => {
    formik.setSubmitting(true);
    const newFormikValues = { ...formik.values, name: data.name, target: data.url };
    save(newFormikValues, id).then(() => {
      formik.setSubmitting(false);
      formik.setValues(newFormikValues);
    });
  };

  return (
    <LinkContainer
      {...provided?.draggableProps}
      ref={provided?.innerRef}
      isDraggable={snapshot ? snapshot.isDragging : false}
    >
      <Content>
        {formik.isSubmitting ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <DraggableElem noDrag={addingMode} {...provided?.dragHandleProps}>
            <StyledDots />
          </DraggableElem>
        )}
        <ImageContainer>
          {icon ? (
            <>
              <IconImage src={icon} />
              <EditButton svgFile={editLinkImageSvg} onClick={editProfileImage} />
              <RemoveImageButton onClick={removeProfileImage} />
            </>
          ) : (
            <>
              <CameraIcon onClick={editProfileImage} />
            </>
          )}
          <HiddenFileUploader ref={fileUploader} save={uploadProfileImage} />
        </ImageContainer>
        <FormContainer>
          <InputsContainer>
            <div>
              <NameInput
                onChange={handleChange}
                name="name"
                type="text"
                value={formik.values.name}
                onBlur={saveOnBlur}
                placeholder={t('Name')}
                disabled={formik.isSubmitting || width <= 768}
              />
            </div>
            <div>
              <LinkInput
                onChange={handleChange}
                name="target"
                type="text"
                value={formik.values.target}
                onBlur={saveOnBlur}
                placeholder={t('Link')}
                disabled={formik.isSubmitting || width <= 768}
              />
            </div>
          </InputsContainer>
          <ActionButtons>
            <EditLinkOnMobileContainer>
              <EditLinkInModal name={name} url={url} onSuccess={saveInModal} />
            </EditLinkOnMobileContainer>
            <ToggleButton
              onChange={handleChange}
              name="active"
              checked={formik.values.active}
              onBlur={saveOnBlur}
              disabled={formik.isSubmitting}
            />
          </ActionButtons>
          <Content>
            {addingMode ? (
              <>
                <AcceptContainer onClick={addNewLink}>
                  <AcceptButton />
                </AcceptContainer>
                <RemoveContainer onClick={removeLink}>
                  <RemoveButton />
                </RemoveContainer>
              </>
            ) : (
              <RemoveContainer onClick={removeLink}>
                <StyledTrashButton />
              </RemoveContainer>
            )}
          </Content>
          <input
            hidden
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          />
        </FormContainer>
      </Content>
    </LinkContainer>
  );
};

const EditLinkOnMobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
`;

export const DraggableLinkBox = (props: LinkBoxProps) => {
  const { id, index } = props;
  return (
    <Draggable draggableId={`${id}`} index={index}>
      {(provided, snapshot) => <LinkBox {...props} provided={provided} snapshot={snapshot} />}
    </Draggable>
  );
};

const StyledTrashButton = styled(TrashButton)`
  width: 25px;
  height: 25px;
`;

const EditButton = styled.div<{ svgFile: string }>`
  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
  display: none;
  top: 0;
  width: 100%;
  background-image: ${(props) => `url("data:image/svg+xml,${props.svgFile}"`};
`;

const RemoveImageButton = styled(RemoveProfileImage)`
  position: absolute;
  background-repeat: no-repeat;
  right: -13px;
  display: none;
  top: -13px;
  width: 28px;
  height: 28px;

  :hover circle {
    fill: #a85353;
  }
`;

const HiddenFileUploader = styled(FileUploader)`
  display: none;
`;

const LinkContainer = styled.div<{ isDraggable: boolean }>`
  margin-top: 5px;
  border-radius: 10px;
  padding: 11px 14px 11px 14px;
  position: relative;
  border: ${({ isDraggable }) => (isDraggable ? '1px solid #BFC2C7' : '1px solid #dee3ed')};

  background: ${({ isDraggable }) => (isDraggable ? '#f2f2f2' : '#f2f3f8')};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButtons = styled(Content)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledDots = styled(Dots)`
  margin-right: 15px;
`;

const DraggableElem = styled.div<{ noDrag?: boolean }>`
  ${({ noDrag }) =>
    noDrag
      ? css``
      : css`
          :hover ${StyledDots} circle {
            fill: #8f929b;
          }
        `}
`;

const Input = styled.input`
  background-color: transparent;
  border: 0;

  width: 100%;
  :focus {
    outline: none;
  }
`;
const NameInput = styled(Input)`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const LinkInput = styled(Input)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #2989db;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  background: #edeff4;
  border: 1px dashed #e2e5eb;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  align-items: center;

  position: relative;
  cursor: pointer;

  :hover > ${EditButton} {
    display: block;
  }

  :hover > ${RemoveImageButton} {
    display: block;
  }
`;

const InputsContainer = styled.div`
  flex-grow: 1;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
`;

const AcceptContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const Spinner = styled(SmallSpinner)`
  margin-right: 10px;
`;

const IconImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 0.25rem;
  border-radius: 10px;
`;
