export const svgToPng = (svg: string, callback: (elem: string) => void) => {
  const url = getSvgUrl(svg);
  svgUrlToPng(url, (imgData) => {
    callback(imgData);
    URL.revokeObjectURL(url);
  });
};
function getSvgUrl(svg: string) {
  return URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
}
function svgUrlToPng(svgUrl: string, callback: (x: string) => void) {
  const svgImage = document.createElement('img');
  svgImage.style.position = 'absolute';
  svgImage.style.top = '-9999px';
  document.body.appendChild(svgImage);
  svgImage.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = svgImage.clientWidth;
    canvas.height = svgImage.clientHeight;
    const canvasCtx = canvas.getContext('2d');
    if (canvasCtx) {
      canvasCtx.drawImage(svgImage, 0, 0);
      const imgData = canvas.toDataURL('image/png');
      callback(imgData);
    }
    // document.body.removeChild(imgPreview);
  };
  svgImage.src = svgUrl;
}
