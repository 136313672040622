// @flow
import * as React from 'react';
import styled from 'styled-components';
import { ChangeEvent, useRef } from 'react';

type Props = {
  name: string;
  checked: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  disabled: boolean;
  onBlur: (e: React.FocusEvent) => void;
};

export const ToggleButton = (props: Props) => {
  const checkBox = useRef<HTMLInputElement>(null);
  const onChange = async (e: ChangeEvent<any>) => {
    if (checkBox.current) {
      await props.onChange(e);
      checkBox.current.blur();
    }
  };

  const onBlur = (e: React.FocusEvent) => {
    props.onBlur(e);
  };

  return (
    <Switch>
      <Checkbox
        ref={checkBox}
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked}
        onBlur={onBlur}
        name={props.name}
        onChange={onChange}
      />
      <Slider />
    </Switch>
  );
};

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 19px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    border-radius: 50%;
  }
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #2196f3;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + ${Slider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;
