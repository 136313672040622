// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
  children: JSX.Element | string;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
};
export const Button = (props: Props) => {
  const { children, onClick, className, type, disabled } = props;

  return (
    <StyledButton disabled={disabled} className={className} type={type} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
Button.defaultProps = {
  type: 'button',
};

const StyledButton = styled.button`
  outline: none;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;

  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  :disabled {
    background-color: lightgrey;
    color: dimgray;
    border: 0.5px solid lightgrey;
    cursor: default;
  }

  :not(:disabled):hover {
    background-color: #0069d9;
    border-color: #0062cc;
    text-decoration: none;
  }

  :not(:disabled):not(.disabled):active {
    background-color: #0062cc;
    border-color: #005cbf;
  }
`;
