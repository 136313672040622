// @flow
import * as React from 'react';

export const UnitedKingdom = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.86048 3.52075C1.15353 4.44054 0.620453 5.50068 0.310059 6.65237H4.9921L1.86048 3.52075Z"
        fill="#0052B4"
      />
      <path d="M17.69 6.65209C17.3796 5.50044 16.8465 4.4403 16.1395 3.52051L13.008 6.65209H17.69Z" fill="#0052B4" />
      <path
        d="M0.310059 11.3479C0.620488 12.4995 1.15356 13.5597 1.86048 14.4794L4.99199 11.3479H0.310059Z"
        fill="#0052B4"
      />
      <path
        d="M14.4794 1.86052C13.5596 1.15356 12.4995 0.620488 11.3478 0.310059V4.99206L14.4794 1.86052Z"
        fill="#0052B4"
      />
      <path
        d="M3.52063 16.1396C4.44042 16.8465 5.50056 17.3796 6.65221 17.69V13.0081L3.52063 16.1396Z"
        fill="#0052B4"
      />
      <path
        d="M6.65217 0.310059C5.50052 0.620488 4.44039 1.15356 3.52063 1.86048L6.65217 4.99203V0.310059Z"
        fill="#0052B4"
      />
      <path d="M11.3478 17.69C12.4995 17.3796 13.5596 16.8465 14.4794 16.1396L11.3478 13.0081V17.69Z" fill="#0052B4" />
      <path d="M13.008 11.3479L16.1395 14.4795C16.8465 13.5597 17.3796 12.4995 17.69 11.3479H13.008Z" fill="#0052B4" />
      <path
        d="M17.9238 7.8261H10.174H10.1739V0.0761836C9.78964 0.0261563 9.39786 0 9 0C8.60207 0 8.21036 0.0261563 7.8261 0.0761836V7.82603V7.82606H0.0761836C0.0261563 8.21036 0 8.60214 0 9C0 9.39793 0.0261563 9.78964 0.0761836 10.1739H7.82603H7.82606V17.9238C8.21036 17.9738 8.60207 18 9 18C9.39786 18 9.78964 17.9739 10.1739 17.9238V10.174V10.1739H17.9238C17.9738 9.78964 18 9.39793 18 9C18 8.60214 17.9738 8.21036 17.9238 7.8261Z"
        fill="#D80027"
      />
      <path
        d="M11.3478 11.3479L15.364 15.364C15.5487 15.1794 15.7249 14.9864 15.893 14.7863L12.4546 11.3479H11.3478V11.3479Z"
        fill="#D80027"
      />
      <path
        d="M6.65219 11.3479H6.65212L2.63605 15.364C2.82069 15.5487 3.01373 15.7249 3.21384 15.893L6.65219 12.4546V11.3479Z"
        fill="#D80027"
      />
      <path
        d="M6.65213 6.6522V6.65213L2.63603 2.63599C2.45132 2.82063 2.27511 3.01367 2.10699 3.21378L5.54538 6.65217H6.65213V6.6522Z"
        fill="#D80027"
      />
      <path
        d="M11.3478 6.65211L15.364 2.63593C15.1793 2.45122 14.9863 2.27502 14.7862 2.10693L11.3478 5.54532V6.65211V6.65211Z"
        fill="#D80027"
      />
    </svg>
  );
};
