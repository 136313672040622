import * as React from 'react';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { Profile } from './Profile';
import { useCardProfileQuery } from '../../hooks/queriesHooks';

type Props = {
  cardProfileId: number;
  children?: JSX.Element;
};
export const CardProfileContainer = ({ cardProfileId, children }: Props) => {
  const [data, loading, error, setData] = useCardProfileQuery(cardProfileId);

  if (error) return <div>Something wrong!</div>;
  if (!data || loading) return <AbsoluteSpinner />;

  return (
    <Profile cardProfile={data} setCardProfile={setData}>
      {children}
    </Profile>
  );
};
