// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Poland } from '../../assets/icons/flags/Poland';
import { UnitedKingdom } from '../../assets/icons/flags/UnitedKingdom';

export const LanguageChanger = ({ className, text }: { className?: string; text?: string }) => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const langToSet = i18n.language === 'pl' ? 'en' : 'pl';
    i18n.changeLanguage(langToSet);
  };

  const getFlagIcon = (lngTag: string) => {
    switch (lngTag) {
      case 'pl':
        return <Poland />;
      case 'en':
      default:
        return <UnitedKingdom />;
    }
  };

  return (
    <Container className={className} onClick={changeLanguage}>
      {getFlagIcon(i18n.language)} {text}
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
