import React from 'react';
import styled from 'styled-components';

export const Burger = (props: { open: boolean }) => {
  return (
    <>
      <StyledBurger open={props.open}>
        <span />
        <span />
        <span />
      </StyledBurger>
    </>
  );
};

const StyledBurger = styled.div<{ open: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  cursor: pointer;
  margin-right: 10px;

  span {
    width: 1.5rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
`;
