import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { QrCode as QrCodeIcon } from '../../assets/icons/QRCode';
import { QRCodeGeneratorModal } from '../../components/QRCodeGenerator/QRCodeGeneratorModal';

type Props = { url: string; cardName: string };
export const QrCodeGenerator = ({ url, cardName }: Props) => {
  const [open, setOpen] = useState(false);

  const openModalWithQR = () => {
    setOpen(true);
  };

  const closeModalWithQR = () => {
    setOpen(false);
  };

  return (
    <>
      <QrCodeIconContainer onClick={openModalWithQR}>
        <QrCodeIcon />
      </QrCodeIconContainer>
      <QRCodeGeneratorModal open={open} closeModal={closeModalWithQR} urlToQr={url} cardName={cardName} />
    </>
  );
};

const QrCodeIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 14px;
`;
