import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardProfileSetType } from './types/UserType';
import { CardProfileContainer } from './CardProfileContainer';
import { AddButton } from '../../assets/icons/AddButton';
import { CreateNewCardProfileModal } from './CreateNewCardProfileModal';

type Props = {
  cardProfiles: CardProfileSetType[];
  userId: number;
};
export const CardProfileSelector = ({ cardProfiles, userId }: Props) => {
  const { t } = useTranslation();
  const [selectedProfileId, setSelectedProfileId] = useState(cardProfiles[0].id);
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState(
    cardProfiles.map((cp) => {
      return { value: cp.id, label: cp.name };
    }),
  );

  const cardProfileChanged = async (selected: { value: number; label: string }) => {
    setSelectedProfileId(selected.value);
  };

  const addNewCardProfile = async (_data: { id: number; name: string }) => {
    setOptions([...options, { value: _data.id, label: _data.name }]);
    setModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const isCreateNewCardAvailable = () => options.length < 3;

  return (
    <div>
      <CardProfileContainer cardProfileId={selectedProfileId}>
        <SelectContainer>
          <span>{t('ProfileLabel')}</span>
          <StyledSelect
            onChange={cardProfileChanged}
            defaultValue={options.find((option) => option.value === selectedProfileId)}
            options={options}
          />
          {isCreateNewCardAvailable() && (
            <>
              <AddNewCardProfileButton onClick={() => setModalOpen(true)}>
                <StyledAddButton />
              </AddNewCardProfileButton>
              <CreateNewCardProfileModal
                open={modalOpen}
                onClose={closeModal}
                onSuccess={addNewCardProfile}
                userId={userId}
              />
            </>
          )}
        </SelectContainer>
      </CardProfileContainer>
    </div>
  );
};

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const AddNewCardProfileButton = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  width: 300px;
  @media (max-width: 768px) {
    width: 50vw;
  }
  margin-left: 10px;
`;

const StyledAddButton = styled(AddButton)`
  height: 20px;
`;
