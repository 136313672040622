import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from './Tab';

type TabPanelProps = {
  onTabChange: (index: number) => void;
  children: JSX.Element[];
  className?: string;
};

export const TabPanel = (props: TabPanelProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleClick = (index: number) => {
    setSelectedTabIndex(index);
    props.onTabChange(index);
  };

  const renderTabs = () => {
    const tabs = [];

    for (let i = 0; i < props.children.length; i += 1) {
      tabs.push(
        <Tab
          key={`tab-${i}`}
          label={props.children[i].props.title}
          index={i}
          isSelected={i === selectedTabIndex}
          onClick={handleClick}
        />,
      );
    }

    return tabs;
  };

  const renderTabContent = () => {
    return props.children[selectedTabIndex].props.children;
  };

  if (selectedTabIndex == null) {
    return <div>NOPE</div>;
  }

  return (
    <Container2 className={props.className}>
      <Header className="tab-panel__header">{renderTabs()}</Header>
      <Content className="tab-panel__content">{renderTabContent()}</Content>
    </Container2>
  );
};

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  font-size: 14px;
  top: 1px;
  display: flex;
  white-space: nowrap;
  border-right: 1px solid #f8f9fa;

  @media (max-width: 768px) {
    width: 70vw;
    overflow-x: auto;
  }
`;

const Content = styled.div`
  padding: 10px;
  height: auto;
  width: 100%;
  border-top: 1px solid #f8f9fa;
`;
