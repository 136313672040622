import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactCrop, { Crop } from 'react-image-crop';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';
import 'react-image-crop/dist/ReactCrop.css';

type Props = {
  open: boolean;
  fileToCrop: string | ArrayBuffer | null;
  onSuccess: (file: File) => void;
  onCancel: () => void;
};
export const CropImageModal = (props: Props) => {
  const { open, onCancel, onSuccess, fileToCrop } = props;
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 30, aspect: 1 });
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const { t } = useTranslation();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const cropCancel = () => {
    onCancel();
  };

  const cropSuccess = () => {
    if (previewCanvasRef.current && completedCrop) {
      generateFile(previewCanvasRef.current, completedCrop, onSuccess);
    }
  };

  useEffect(() => {
    if (completedCrop && previewCanvasRef && previewCanvasRef.current && imgRef && imgRef.current) {
      const image: HTMLImageElement = imgRef.current;
      const canvas: HTMLCanvasElement = previewCanvasRef.current;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx: any = canvas.getContext('2d');

      canvas.width = (completedCrop.width ?? 0) * pixelRatio;
      canvas.height = (completedCrop.height ?? 0) * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        (completedCrop.x ?? 0) * scaleX,
        (completedCrop.y ?? 0) * scaleY,
        (completedCrop.width ?? 0) * scaleX,
        (completedCrop.height ?? 0) * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height,
      );
    }
  }, [completedCrop]);

  const ModalButtons = () => {
    return (
      <>
        <StyledButton onClick={cropSuccess} disabled={!completedCrop?.width || !completedCrop?.height}>
          {t('Accept')}
        </StyledButton>
        <Button onClick={cropCancel}>{t('Cancel')}</Button>
      </>
    );
  };

  return open ? (
    <Modal id="CropFileModal" footerContent={<ModalButtons />} title={t('CropProfileImageTitle')}>
      <>
        <ReactCrop
          src={fileToCrop as string}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          circularCrop
        />
        <div>
          <canvas
            hidden
            ref={previewCanvasRef}
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
            }}
          />
        </div>
      </>
    </Modal>
  ) : (
    <></>
  );
};

const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas: HTMLCanvasElement, newWidth: number, newHeight: number) {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx: any = tmpCanvas.getContext('2d');
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

  return tmpCanvas;
}

const generateFile = async (previewCanvas: HTMLCanvasElement, crop: Crop, callback: (file: File) => void) => {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width ?? 0, crop.height ?? 0);

  await canvas.toBlob(
    (blob: Blob) => {
      const file: File = new File([blob], 'profile_image.png', { type: 'image/png' });
      callback(file);
    },
    'image/png',
    1,
  );
};

const StyledButton = styled(Button)`
  margin-right: 10px;
`;
