import * as React from 'react';
import { SVGProps } from 'react';

const Youtube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.522 17.874c.49 1.738 1.989 2.056 2.089 2.117 2.467.672 16.295.674 18.799 0 1.715-.496 2.03-2.017 2.089-2.117.653-3.474.696-8.003-.03-11.945l.03.196c-.49-1.738-1.989-2.056-2.089-2.117-2.434-.661-16.298-.686-18.799 0-1.715.497-2.03 2.017-2.089 2.117-.699 3.651-.734 7.84 0 11.749zm9.086-2.223v-7.293l6.266 3.652z"
      fill="#e53935"
    />
  </svg>
);
const Instagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <linearGradient
      id="SVG_ID_1"
      gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)"
      gradientUnits="userSpaceOnUse"
      x1="-37.106"
      x2="-26.555"
      y1="-72.705"
      y2="-84.047"
    >
      <stop offset="0" stopColor="#fd5" />
      <stop offset=".5" stopColor="#ff543e" />
      <stop offset="1" stopColor="#c837ab" />
    </linearGradient>
    <path
      d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z"
      fill="url(#SVG_ID_1)"
    />
    <path
      d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z"
      fill="#fff"
    />
  </svg>
);
const TikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="554" height="627" viewBox="0 0 554 627" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M215.793 246.572V222.174C207.324 220.833 198.77 220.098 190.196 219.975C107.188 219.798 33.7001 273.589 8.77295 352.765C-16.1542 431.94 13.2694 518.129 81.403 565.536C56.4183 538.796 39.7068 505.397 33.2859 469.369C26.865 433.341 31.009 396.225 45.2178 362.5C59.4267 328.776 83.0928 299.884 113.36 279.314C143.627 258.743 179.201 247.373 215.788 246.575L215.793 246.572Z"
      fill="#25F4EE"
    />
    <path
      d="M220.395 522.941C266.805 522.877 304.953 486.316 306.985 439.95V26.198H382.572C381.029 17.5522 380.293 8.7819 380.374 0L276.985 0V413.354C275.266 459.955 237.027 496.868 190.395 496.942C176.46 496.824 162.752 493.398 150.4 486.944C158.406 498.035 168.919 507.077 181.083 513.333C193.246 519.588 206.717 522.881 220.395 522.941V522.941ZM523.756 166.58V143.582C495.938 143.593 468.736 135.385 445.567 119.987C465.877 143.599 493.324 159.954 523.756 166.58V166.58Z"
      fill="#25F4EE"
    />
    <path
      d="M445.567 119.987C422.742 93.9971 410.16 60.5861 410.171 25.9961H382.572C386.137 45.1205 393.561 63.3179 404.392 79.4779C415.222 95.6378 429.232 109.421 445.567 119.987V119.987ZM190.196 323.364C170.839 323.462 152.071 330.028 136.876 342.019C121.68 354.009 110.929 370.735 106.331 389.538C101.734 408.341 103.554 428.141 111.502 445.79C119.451 463.44 133.071 477.925 150.198 486.945C140.834 474.015 135.227 458.749 133.995 442.832C132.763 426.916 135.956 410.969 143.219 396.753C150.483 382.537 161.535 370.605 175.154 362.277C188.774 353.948 204.43 349.547 220.395 349.559C229.073 349.672 237.692 351.023 245.989 353.571V248.372C237.516 247.101 228.963 246.433 220.395 246.373H215.793V326.362C207.451 324.125 198.829 323.115 190.196 323.364V323.364Z"
      fill="#FE2C55"
    />
    <path
      d="M523.757 166.58V246.572C472.257 246.472 422.094 230.171 380.374 199.977V410.153C380.152 515.028 295.071 599.932 190.196 599.932C151.246 600.002 113.234 587.984 81.4033 565.536C107.359 593.454 141.121 612.908 178.291 621.365C215.461 629.822 254.316 626.889 289.795 612.948C325.274 599.008 355.733 574.706 377.204 543.208C398.675 511.71 410.163 474.477 410.171 436.357V226.776C452.032 256.77 502.256 272.859 553.754 272.77V169.772C543.672 169.742 533.619 168.673 523.757 166.58V166.58Z"
      fill="#FE2C55"
    />
    <path
      d="M380.374 410.153V199.977C422.22 229.998 472.454 246.09 523.956 245.971V165.982C493.53 159.556 466.018 143.413 445.567 119.987C429.232 109.421 415.222 95.6378 404.392 79.4779C393.561 63.3179 386.137 45.1205 382.572 25.9961H306.985V439.956C306.249 457.975 299.918 475.317 288.872 489.572C277.827 503.828 262.615 514.288 245.35 519.5C228.086 524.712 209.627 524.416 192.538 518.655C175.449 512.893 160.58 501.95 149.996 487.349C132.866 478.331 119.243 463.845 111.292 446.195C103.342 428.544 101.521 408.742 106.119 389.938C110.716 371.133 121.469 354.405 136.667 342.415C151.865 330.424 170.635 323.858 189.994 323.762C198.676 323.84 207.299 325.188 215.591 327.763V247.771C178.807 248.395 143 259.707 112.534 280.33C82.0691 300.952 58.2621 329.993 44.0163 363.912C29.7704 397.831 25.7012 435.162 32.3048 471.353C38.9085 507.544 55.8996 541.032 81.2065 567.734C113.35 589.443 151.416 600.688 190.196 599.932C295.071 599.932 380.152 515.028 380.374 410.153V410.153Z"
      fill="black"
    />
  </svg>
);
const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21 0h-18c-1.655 0-3 1.345-3 3v18c0 1.654 1.345 3 3 3h18c1.654 0 3-1.346 3-3v-18c0-1.655-1.346-3-3-3z"
      fill="#3b5999"
    />
    <path
      d="m16.5 12v-3c0-.828.672-.75 1.5-.75h1.5v-3.75h-3c-2.486 0-4.5 2.014-4.5 4.5v3h-3v3.75h3v8.25h4.5v-8.25h2.25l1.5-3.75z"
      fill="#fff"
    />
  </svg>
);
const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21.552 7.749c0-.217-.008-.428-.018-.636.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.752.206-1.678.198-2.221.078.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 9.054 0 14.004-7.5 14.004-14.001z"
      fill="#55acee"
    />
  </svg>
);
const Twitch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#673ab7">
      <path d="m.975 4.175v16.694h5.749v3.131h3.139l3.134-3.132h4.705l6.274-6.258v-14.61h-21.434zm3.658-2.09h17.252v11.479l-3.66 3.652h-5.751l-3.134 3.127v-3.127h-4.707z" />
      <path d="m10.385 6.262h2.09v6.26h-2.09z" />
      <path d="m16.133 6.262h2.091v6.26h-2.091z" />
    </g>
  </svg>
);
const Spotify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" fill="#4caf50" r="12" />
    <g fill="#212121">
      <path d="m16.872 17.656v.001c-.203 0-.329-.063-.518-.174-3.019-1.82-6.532-1.896-10.002-1.185-.189.049-.436.126-.576.126-.47 0-.765-.373-.765-.765 0-.499.295-.736.659-.813 3.963-.875 8.013-.798 11.467 1.268.295.189.47.358.47.798 0 .438-.344.744-.735.744z" />
      <path d="m18.175 14.483h-.001c-.252 0-.421-.111-.596-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-.518 0-.938-.421-.938-.938s.252-.861.75-1.001c1.345-.378 2.719-.659 4.732-.659 3.14 0 6.174.779 8.565 2.202.392.232.547.533.547.953-.005.521-.411.943-.938.943z" />
      <path d="m4.548 6.998c1.703-.499 3.61-.735 5.686-.735 3.532 0 7.234.735 9.939 2.313.378.218.624.518.624 1.093 0 .658-.533 1.127-1.122 1.127l-.001-.001c-.252 0-.407-.063-.625-.189-3.444-2.056-9.605-2.549-13.591-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z" />
    </g>
  </svg>
);
const Snapchat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.413 19.514c.981-.157 3.029-.528 3.086-1.517.016-.278-.178-.525-.45-.569v-.001c-3.027-.508-4.933-3.993-4.639-4.69.212-.504 1.158-.699 1.575-.863 1.996-.8 1.136-2.169-.043-2.169-.531 0-1.161.576-1.742.305.18-3.065.5-5.224-1.668-7.312-2.814-2.706-8.524-2.391-10.472 2.055-.579 1.319-.357 3.73-.264 5.257-.41.183-.996-.014-1.401-.208-1.021-.486-2.767 1.111-.381 2.071 1.034.413 2.012.531 1.466 1.661-.059.108-.391.932-1.17 1.854-2.246 2.661-4.292 1.573-3.754 2.848v.002c.341.811 2.153 1.136 3.029 1.276.241.459.066 1.513.887 1.513.441 0 1.281-.365 2.597-.14 1.774.302 3.789 4.054 8.248.852l.001-.002c.961-.69 1.636-1.201 3.68-.791 1.482.292 1.07-.749 1.415-1.432z"
      fill="#ffeb3b"
    />
    <path
      d="m11.945 23.494c.017.003-.033.006-.085.006-1.584 0-2.629-.752-3.469-1.357-3.069-2.21-4.45 1.145-5.19-2.186-4.242-.753-3.409-2.845-2.33-3.024 1.243-.21 2.258-.919 3.059-1.867.525-.621 1.293-1.915 1.199-2.137-.1-.24-1.006-.473-1.299-.591-3.017-1.216-.87-3.769.779-2.988.289.139.505.198.66.221-.092-1.501-.25-3.689.334-5.019 2.369-5.414 10.414-5.447 12.794.001.586 1.334.425 3.517.333 4.997.489-.122 1.035-.57 1.905-.21 1.36.495 1.698 2.331-.834 3.126-.23.075-.848.274-.928.465-.117.28 1.447 3.532 4.261 4.004.524.087.897.556.866 1.092-.072 1.27-1.934 1.705-3.198 1.93-.185.837-.322 1.555-1.246 1.555-1.58-.093-2.081-.708-3.944.634-1.102.788-2.186 1.441-3.667 1.348zm2.985-2.092c2.263-1.627 2.814-1.022 4.624-.891.217 0 .062-.373.528-1.45.396-.079 2.635-.394 2.899-1.017h-.001l-.014-.122c-3.368-.566-4.835-4.022-4.896-4.169-.999-2.056 1.757-2.039 2.471-2.784.29-.304.082-.57-.27-.698-.598-.248-.798.266-1.731.309-.477-.021-.587-.151-.86-.261.222-3.117.528-5.312-1.495-7.261-2.554-2.456-7.857-2.241-9.666 1.895-.545 1.24-.301 3.75-.201 5.373-.193.037-.734.603-2.139-.074-.385-.183-1.732.449.022 1.157.472.189 1.54.391 1.85 1.135.476 1.146-1.839 4.841-5.017 5.375v.001l-.017.124c.282.672 2.794.984 2.894 1.015.464 1.117.164.474.424 1.37.106.36.993-.353 2.82-.035 1.909.325 3.778 3.871 7.775 1.008z"
      fill="#212121"
    />
  </svg>
);
const Github = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"
      fill="#212121"
    />
  </svg>
);
const LinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#0077b5">
      <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" />
      <path d="m.396 7.977h4.976v16.023h-4.976z" />
      <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" />
    </g>
  </svg>
);
const Pinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.326 0c-11.309.001-12.268 12.107-6.978 14.445.544.245.472-.054.94-1.844.037-.149.018-.278-.102-.417-2.726-3.153-.532-9.635 5.751-9.635 9.093 0 7.394 12.582 1.582 12.582-1.498 0-2.614-1.176-2.261-2.631.428-1.733 1.266-3.596 1.266-4.845 0-3.148-4.69-2.681-4.69 1.49 0 1.289.456 2.159.456 2.159s-1.509 6.096-1.789 7.235c-.322 1.308-.164 6.129.399 5.391.149-.195 1.973-2.797 2.484-4.678.186-.685.949-3.465.949-3.465.503.908 1.953 1.668 3.498 1.668 4.596 0 7.918-4.04 7.918-9.053-.016-4.806-4.129-8.402-9.423-8.402z"
      fill="#f44336"
    />
  </svg>
);
const Soundcloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#ff9800">
      <path d="m20.25 11c-.267 0-.529.029-.782.082-.337-3.409-3.222-6.082-6.718-6.082-.414 0-.75.336-.75.75v12c0 .414.336.75.75.75h7.5c2.069 0 3.75-1.681 3.75-3.75 0-2.067-1.681-3.75-3.75-3.75z" />
      <path d="m9 7.25v10.5c0 .414.336.75.75.75s.75-.336.75-.75v-10.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" />
      <path d="m6 10.25v7.5c0 .414.336.75.75.75s.75-.336.75-.75v-7.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" />
      <path d="m3 17.75c0 .414.336.75.75.75s.75-.336.75-.75v-7.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" />
      <path d="m.75 18.5c.414 0 .75-.336.75-.75v-4.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75v4.5c0 .414.336.75.75.75z" />
    </g>
  </svg>
);
const Discord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#5c6bc0">
      <path d="m3.58 21.196h14.259l-.681-2.205c.101.088 5.842 5.009 5.842 5.009v-21.525c-.068-1.338-1.22-2.475-2.648-2.475l-16.767.003c-1.427 0-2.585 1.139-2.585 2.477v16.24c0 1.411 1.156 2.476 2.58 2.476zm10.548-15.513-.033.012.012-.012zm-7.631 1.269c1.833-1.334 3.532-1.27 3.532-1.27l.137.135c-2.243.535-3.26 1.537-3.26 1.537.104-.022 4.633-2.635 10.121.066 0 0-1.019-.937-3.124-1.537l.186-.183c.291.001 1.831.055 3.479 1.26 0 0 1.844 3.15 1.844 7.02-.061-.074-1.144 1.666-3.931 1.726 0 0-.472-.534-.808-1 1.63-.468 2.24-1.404 2.24-1.404-3.173 1.998-5.954 1.686-9.281.336-.031 0-.045-.014-.061-.03v-.006c-.016-.015-.03-.03-.061-.03h-.06c-.204-.134-.34-.2-.34-.2s.609.936 2.174 1.404c-.411.469-.818 1.002-.818 1.002-2.786-.066-3.802-1.806-3.802-1.806 0-3.876 1.833-7.02 1.833-7.02z" />
      <path d="m14.308 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.576-1.335-1.29-1.335v.003c-.708 0-1.288.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z" />
      <path d="m9.69 12.771c.711 0 1.29-.6 1.29-1.34 0-.735-.575-1.335-1.286-1.335l-.004.003c-.711 0-1.29.598-1.29 1.338 0 .734.579 1.334 1.29 1.334z" />
    </g>
  </svg>
);
const Skype = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m23.309 14.547c1.738-7.81-5.104-14.905-13.139-13.543-4.362-2.707-10.17.352-10.17 5.542 0 1.207.333 2.337.912 3.311-1.615 7.828 5.283 14.821 13.311 13.366.936.495 2.004.777 3.139.777 4.949 0 8.118-5.133 5.947-9.453zm-11.213 5.332c-2.342 0-4.63-.565-5.984-2.499-2.018-2.854.643-4.282 1.949-3.194 1.09.919.748 3.142 3.949 3.142 1.411 0 3.153-.602 3.153-2.001 0-2.815-6.245-1.483-8.728-4.552-.912-1.124-1.084-3.107.036-4.545 1.952-2.512 7.68-2.665 10.143-.768 2.274 1.76 1.66 4.096-.175 4.096-2.207 0-1.047-2.888-4.61-2.888-2.583 0-3.599 1.837-1.78 2.731 2.421 1.202 8.75.827 8.75 5.603-.008 3.357-3.247 4.875-6.703 4.875z"
      fill="#03a9f4"
    />
  </svg>
);
const Steam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#212121">
      <path d="m17.903 1.75c-3.349 0-6.081 2.696-6.107 6.02l-3.803 5.427c-1.061-.096-1.976.123-2.785.614l-5.208-2.097v5.727l3.08 1.233c.445 2.039 2.278 3.576 4.447 3.576 2.358 0 4.312-1.803 4.527-4.099l5.855-4.259c3.359 0 6.091-2.711 6.091-6.073l-.001-.001c0-3.346-2.732-6.068-6.096-6.068zm-10.376 19.292c-1.287 0-2.395-.721-2.958-1.772.546.208 1.098.437 1.645.651 1.367.56 2.92-.107 3.466-1.468.552-1.361-.118-2.897-1.483-3.442l-1.393-.56c.241-.054.482-.08.723-.08 1.859 0 3.348 1.483 3.348 3.335 0 1.853-1.489 3.336-3.348 3.336zm10.382-9.156c-2.261 0-4.098-1.815-4.098-4.067 0-2.242 1.832-4.067 4.098-4.067 2.245 0 4.082 1.825 4.082 4.067 0 2.258-1.838 4.073-4.082 4.067z" />
      <path d="m17.92 4.556c-1.805 0-3.267 1.457-3.267 3.255s1.457 3.255 3.267 3.255c1.794 0 3.267-1.452 3.267-3.255s-1.473-3.255-3.267-3.255z" />
    </g>
  </svg>
);
const StackOverflow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#ff9800">
      <path d="m6.444 14.839 10.338 2.196.433-2.089-10.338-2.212z" />
      <path d="m6.215 17.571h10.566v2.127h-10.566z" />
      <path d="m7.8 9.831 9.572 4.526.887-1.944-9.577-4.538z" />
      <path d="m17.373 14.358-.001-.001-.001.001z" />
    </g>
    <path d="m2 15.429v8.571h18.992v-8.571h-2.113v6.428h-14.766v-6.428z" fill="#757575" />
    <path d="m10.453 5.063 8.109 6.873 1.346-1.65-8.109-6.873z" fill="#ff9800" />
    <path d="m22 8.587-6.302-8.587-1.691 1.286 6.302 8.587z" fill="#ff9800" />
  </svg>
);
const Codepen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.455 16.512 10.969 7.314c.374.23.774.233 1.152 0l10.969-7.314c.281-.187.455-.522.455-.857v-7.312c0-.335-.174-.67-.455-.857l-10.969-7.313c-.374-.23-.774-.232-1.152 0l-10.969 7.313c-.281.187-.455.522-.455.857v7.312c0 .335.174.67.455.857zm10.514 4.528-8.076-5.384 3.603-2.411 4.473 2.987zm2.062 0v-4.808l4.473-2.987 3.603 2.411zm8.907-7.314-2.585-1.727 2.585-1.728zm-8.907-10.767 8.076 5.384-3.603 2.411-4.473-2.987zm-1.031 6.602 3.643 2.438-3.643 2.438-3.643-2.438zm-1.031-6.602v4.808l-4.473 2.987-3.603-2.411zm-8.906 7.314v-.001l2.585 1.728-2.585 1.728z"
      fill="#212121"
    />
  </svg>
);
const GoodReads = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5.111 18.907h.129c.585 0 1.176 0 1.762.005.074 0 .143-.019.166.098.328 1.636 1.383 2.559 2.9 2.995 1.241.356 2.495.366 3.749.084 1.558-.347 2.582-1.327 3.136-2.831.369-1.008.494-2.053.508-3.117.005-.272.014-2.203-.009-2.475l-.041-.014c-.037.07-.079.136-.115.206-1.019 2.02-2.826 3.159-4.861 3.239-4.75.188-7.812-2.672-7.932-8.259-.023-1.111.083-2.198.383-3.267.95-3.333 3.44-5.541 7.097-5.569 2.826-.019 4.681 1.814 5.359 3.295.023.052.06.108.11.089v-2.888h2.043c0 13.139.005 15.572.005 15.572-.005 3.68-1.232 6.736-4.75 7.603-3.205.792-7.332.225-9.039-2.681-.369-.633-.544-1.327-.599-2.086zm6.747-17.194c-2.421-.023-5.004 1.908-5.304 6.272-.189 2.766.683 5.728 3.298 6.966 1.273.605 3.427.703 4.995-.408 2.195-1.556 2.891-4.547 2.527-7.219-.448-3.333-2.205-5.625-5.516-5.611z"
      fill="#4e342e"
    />
  </svg>
);
const WhatsApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.52 3.449c-2.28-2.204-5.28-3.449-8.475-3.449-9.17 0-14.928 9.935-10.349 17.838l-1.696 6.162 6.335-1.652c2.76 1.491 5.021 1.359 5.716 1.447 10.633 0 15.926-12.864 8.454-20.307z"
      fill="#eceff1"
    />
    <path
      d="m12.067 21.751-.006-.001h-.016c-3.182 0-5.215-1.507-5.415-1.594l-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-8.793 10.745-13.19 16.963-6.975 6.203 6.15 1.848 16.875-7.026 16.875z"
      fill="#4caf50"
    />
    <path
      d="m17.507 14.307-.009.075c-.301-.15-1.767-.867-2.04-.966-.613-.227-.44-.036-1.617 1.312-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z"
      fill="#fafafa"
    />
  </svg>
);
const Disqus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#2196f3">
      <path d="m12.339 9.013h-1.292v5.975h1.292c1.897 0 3.157-1.075 3.157-2.971v-.033c0-1.88-1.26-2.971-3.157-2.971z" />
      <path d="m12.438.25c-6.387 0-11.564 5.261-11.564 11.75 0 1.74.375 3.389 1.043 4.875l-1.917 4.77 4.962-.683c7.435 6.409 19.038 1.09 19.038-8.962 0-6.489-5.177-11.75-11.562-11.75zm6.313 11.716c0 3.39-2.405 5.909-6.526 5.909h-4.351v-11.75h4.417c4.089 0 6.461 2.418 6.461 5.808v.033z" />
    </g>
  </svg>
);
const Medium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m22.085 4.733 1.915-1.832v-.401h-6.634l-4.728 11.768-5.379-11.768h-6.956v.401l2.237 2.693c.218.199.332.49.303.783v10.583c.069.381-.055.773-.323 1.05l-2.52 3.054v.396h7.145v-.401l-2.52-3.049c-.273-.278-.402-.663-.347-1.05v-9.154l6.272 13.659h.729l5.393-13.659v10.881c0 .287 0 .346-.188.534l-1.94 1.877v.402h9.412v-.401l-1.87-1.831c-.164-.124-.249-.332-.214-.534v-13.467c-.035-.203.049-.411.213-.534z"
      fill="#212121"
    />
  </svg>
);
const Messenger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0 11.111c0 3.496 1.744 6.615 4.471 8.652v4.237l4.086-2.242c1.09.301 2.245.465 3.442.465 6.627 0 12-4.974 12-11.111.001-6.137-5.372-11.112-11.999-11.112s-12 4.974-12 11.111zm10.734-3.112 3.13 3.259 5.887-3.259-6.56 6.962-3.055-3.258-5.963 3.259z"
      fill="#2196f3"
    />
  </svg>
);
const Trello = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21 0h-18c-1.657 0-3 1.343-3 3v18c0 1.656 1.343 3 3 3h18c1.656 0 3-1.344 3-3v-18c0-1.657-1.344-3-3-3zm-10.56 18.18c0 .795-.645 1.44-1.44 1.44h-4.44c-.795 0-1.44-.646-1.44-1.44v-13.62c0-.795.645-1.44 1.44-1.44h4.44c.795 0 1.44.645 1.44 1.44zm10.44-6c0 .794-.645 1.44-1.44 1.44h-4.44c-.795 0-1.44-.646-1.44-1.44v-7.62c0-.795.646-1.44 1.44-1.44h4.44c.795 0 1.44.645 1.44 1.44z"
      fill="#0079c0"
    />
  </svg>
);
const Vimeo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m20.497 1.505c-3.328-.121-5.576 1.787-6.762 5.694.61-.273 1.203-.395 1.777-.395 4.219 0-.971 8.155-2.263 8.155-1.522 0-2.437-7.284-2.747-9.273-.431-2.765-1.58-4.058-3.447-3.877-.789.075-1.975.799-3.554 2.181-1.15 1.055-2.316 2.105-3.501 3.16l1.129 1.472c1.075-.762 1.702-1.144 1.881-1.144 3.092 0 2.888 15.022 7.783 15.022 4.702 0 12.978-11.521 13.184-16.139l.013-.01c.134-3.165-1.021-4.785-3.493-4.846z"
      fill="#19b2e5"
    />
  </svg>
);
const Tumblr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    enableBackground="new 0 0 24 24"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m19 22.594-1.175-3.425c-.458.214-1.327.399-1.968.419h-.091c-1.863 0-2.228-1.37-2.244-2.371v-7.47h4.901v-3.633h-4.883v-6.114h-3.575c-.059 0-.162.051-.176.179-.202 1.873-1.098 5.156-4.789 6.469v3.099h2.456v7.842c0 2.655 1.97 6.411 7.148 6.411l-.011-.002h.181c1.786-.03 3.783-.768 4.226-1.404z"
      fill="#33506c"
    />
  </svg>
);

export default [
  { name: 'Youtube', element: Youtube },
  { name: 'Instagram', element: Instagram },
  { name: 'TikTok', element: TikTok },
  { name: 'Facebook', element: Facebook },
  { name: 'Twitter', element: Twitter },
  { name: 'Twitch', element: Twitch },
  { name: 'Spotify', element: Spotify },
  { name: 'Snapchat', element: Snapchat },
  { name: 'Github', element: Github },
  { name: 'LinkedIn', element: LinkedIn },
  { name: 'Pinterest', element: Pinterest },
  { name: 'Soundcloud', element: Soundcloud },
  { name: 'Discord', element: Discord },
  { name: 'Skype', element: Skype },
  { name: 'Steam', element: Steam },
  { name: 'StackOverflow', element: StackOverflow },
  { name: 'Codepen', element: Codepen },
  { name: 'GoodReads', element: GoodReads },
  { name: 'WhatsApp', element: WhatsApp },
  { name: 'Disqus', element: Disqus },
  { name: 'Medium', element: Medium },
  { name: 'Messenger', element: Messenger },
  { name: 'Trello', element: Trello },
  { name: 'Vimeo', element: Vimeo },
  { name: 'Tumblr', element: Tumblr },
];
