export type CardProfileType = {
  id: number;
  displayname: string;
  description: string;
  name: string;
  profile_image: string;
  default: boolean;
  categories: any[];
  address: string;
  links: LinkSet[];
};

export type UserCardProfileType = {
  id: number;
  displayname: string;
  description: string;
  name: string;
  profile_image: string;
  categories: any[];
  links: LinkSet[];
};

export type LinkSet = {
  id: number;
  name: string;
  target: string;
  thumbnail: string;
  is_active: boolean;
  is_icon: boolean;
  linkstab: LinksTab;
  priority: number;
  cardprofile: number;
};

export enum LinksTab {
  LINK = 1,
  SOCIAL_LINK,
}
