import * as React from 'react';
import { UserStateContext } from '../helpers/AppContext';

export const useUserState = () => {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used');
  }
  return context;
};
