import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { ROUTES } from '../../routing/routesConstants';
import { Users } from '../../assets/icons/Users';
import { Help } from '../../assets/icons/Help';
import { Logout } from '../../assets/icons/Logout';
import { useUserDispatch } from '../../hooks/useUserDispatch';
import { removeCurrentUser } from '../../helpers/removeCurrentUser';
import { LanguageChanger } from '../LanguageChanger/LanguageChanger';

const Dropdown = (props: { className?: string }) => {
  const { t } = useTranslation();
  const dispatch = useUserDispatch();

  return (
    <>
      <DropdownMenu className={props.className}>
        <DropdownItem onClick={() => navigate(ROUTES.PROFILE)}>
          <Users />
          {t('DashboardButton')}
        </DropdownItem>
        <DropdownItem onClick={() => navigate(ROUTES.HELP)}>
          <Help />
          {t('MenuItem.Help')}
        </DropdownItem>
        <DropdownItem>
          <LanguageChanger text={t('btn.changeTranslation')} />
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            removeCurrentUser();
            dispatch({ type: 'logout' });
            window.location.reload();
          }}
        >
          <Logout />
          {t('LogoutButton')}
        </DropdownItem>
      </DropdownMenu>
    </>
  );
};

export default Dropdown;

const DropdownMenu = styled.ul`
  background: #f8f9fa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 36px;
  right: 6px;
  list-style: none;
  padding-left: 0;
  display: none;
  margin-top: 20px;
`;

const DropdownItem = styled.li`
  cursor: pointer;
  border-bottom: 1px solid #dee3ed;
  position: relative;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 10px;
  justify-content: start;
  align-items: center;
  user-select: none;

  & svg {
    margin-right: 10px;
  }

  &:hover {
    color: #6d7993;
  }
`;
