import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import footerImageLeft from '../../assets/images/footerLeft.png';
import footerImageRight from '../../assets/images/footerRight.png';

export const WelcomePageFooter = () => {
  const { t } = useTranslation();
  return (
    <FooterSections>
      <FooterSection>
        <SectionImage>
          <StyledImage src={footerImageLeft} />
        </SectionImage>
        <SectionText>{t('WelcomePage.ImagesText')}</SectionText>
        <SectionImage>
          <StyledImage src={footerImageRight} />
        </SectionImage>
      </FooterSection>
    </FooterSections>
  );
};

const FooterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 3rem;
`;

const FooterSections = styled.div`
  width: 70vw;
`;

const SectionImage = styled.div``;

const StyledImage = styled.img`
  width: 360px;

  @media (max-width: 400px) {
    width: 280px;
  }
`;

const SectionText = styled.div`
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 1.375rem;
  }
`;
