import * as React from 'react';
import { ChangeEvent, RefObject, useState } from 'react';
import styled from 'styled-components';
import { CropImageModal } from '../CropImageModal/CropImageModal';

type Props = {
  className?: string;
  save: (file: File) => void;
};

export const FileUploader = React.forwardRef((props: Props, ref: RefObject<HTMLInputElement>) => {
  const { save, className } = props;
  const [upImg, setUpImg] = useState<string | ArrayBuffer | null>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result);
        setOpenModal(true);
        setUploadDisabled(true);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const cancelCrop = () => {
    setOpenModal(false);
    setTimeout(() => {
      setUploadDisabled(false);
    }, 400);
  };

  const successCrop = async (file: File) => {
    if (file) {
      setOpenModal(false);
      await save(file);
      setTimeout(() => {
        setUploadDisabled(false);
      }, 400);
    }
  };

  return (
    <div className={className}>
      <div>
        <UploadFileInput ref={ref} type="file" accept="image/*" onChange={onFileChange} disabled={uploadDisabled} />
      </div>
      <CropImageModal open={openModal} onCancel={cancelCrop} onSuccess={successCrop} fileToCrop={upImg} />
    </div>
  );
});

const UploadFileInput = styled.input`
  color: transparent;

  ::-webkit-file-upload-button {
    visibility: hidden;
  }

  ::before {
    content: 'Select some files';
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }

  :hover::before {
    border-color: black;
  }

  :active,
  :active::before {
    outline: none;
  }

  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;
