import * as React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from '@reach/router';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text, Label } from '../../components/FormTheme';
import { register } from '../../services/authentication.service';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { ROUTES } from '../../routing/routesConstants';
import { useUserState } from '../../hooks/useUserState';
import { Button } from '../../components/Button/Button';
import { StyledInput } from '../../components/StyledInput/StyledInput';

interface RegisterFormValues {
  username: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const RegisterForm = (props: { className?: string; newUsername?: string }) => {
  const { className, newUsername } = props;
  const initialValues: RegisterFormValues = {
    username: newUsername || '',
    email: '',
    password: '',
    repeatPassword: '',
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUserState();

  const onSubmit = (values: RegisterFormValues) => {
    const { username, email, password, repeatPassword } = values;

    if (password !== repeatPassword) {
      formik.setFieldError('password', t('PasswordsNotMatch'));
      formik.setValues({ ...values, password: '', repeatPassword: '' });
      formik.setSubmitting(false);
      return;
    }

    register(username, email, password).then(
      () => {
        navigate('/login');
      },
      (error) => {
        console.log('ERROR: ', error);
        formik.setSubmitting(false);
      },
    );
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(t('RequiredField')),
    password: Yup.string()
      .required(t('RequiredField'))
      .min(8, t('PasswordToMin', { min: '8' })),
    email: Yup.string().required(t('RequiredField')).email(),
    repeatPassword: Yup.string()
      .required(t('RequiredField'))
      .min(8, t('PasswordToMin', { min: '8' })),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (user) {
      navigate(ROUTES.PROFILE);
    }
  }, []);

  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      {formik.status && <Text color="red">{formik.status}</Text>}
      {formik.isSubmitting && <AbsoluteSpinner />}
      <Label>
        {formik.touched.username && formik.errors.username && <Text color="red">{formik.errors.username}</Text>}
        <StyledInput
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          type="text"
          name="username"
          labelName={t('UsernameLabel')}
        />
      </Label>
      <Label>
        {formik.touched.email && formik.errors.email && <Text color="red">{formik.errors.email}</Text>}
        <StyledInput
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          type="email"
          name="email"
          labelName={t('EmailLabel')}
        />
      </Label>
      <Label>
        {formik.touched.password && formik.errors.password && <Text color="red">{formik.errors.password}</Text>}
        <StyledInput
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          type="password"
          name="password"
          labelName={t('PasswordLabel')}
        />
      </Label>
      <Label>
        {formik.touched.repeatPassword && formik.errors.repeatPassword && (
          <Text color="red">{formik.errors.repeatPassword}</Text>
        )}
        <StyledInput
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repeatPassword}
          type="password"
          name="repeatPassword"
          labelName={t('RepeatPasswordLabel')}
        />
      </Label>
      <StyledButton type="submit">{t('RegisterButton')}</StyledButton>
    </form>
  );
};

const StyledButton = styled(Button)`
  width: 300px;
`;
