import * as React from 'react';
import styled from 'styled-components';
import { KeyboardEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cardName: string;
  loading?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export const NewProfileNameInput = (props: Props) => {
  const { className, onChange, onKeyPress, cardName, loading } = props;
  const { t } = useTranslation();

  return (
    <InputContainer className={className}>
      <CardNameInput>
        <OneLink2MeTitle>OneLink2.me/</OneLink2MeTitle>
        <Input
          type="text"
          onChange={onChange}
          value={cardName}
          placeholder={t('NewProfileNameInputPlaceholder')}
          disabled={loading}
          required
          onKeyPress={onKeyPress}
        />
      </CardNameInput>
    </InputContainer>
  );
};

const CardNameInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border: 1px solid #e2e4e8;
  border-radius: 40px;
  padding: 5px 15px;
  width: 100%;
`;

const OneLink2MeTitle = styled.div`
  font-size: 24px;
  color: #000000;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

const Input = styled.input`
  border: 0;
  outline: none;
  font-size: 20px;
  width: 150px;

  @media (max-width: 500px) {
    font-size: 18px;
    width: 130px;
  }
`;

const InputContainer = styled.div`
  display: flex;
`;
