import * as React from 'react';
import { UserPage } from './UserPage';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { useCardProfileQueryByUserName } from '../../hooks/queriesHooks';

type Props = {
  cardname: string;
  needRedirect: (value: boolean) => void;
};
export const UserPageContainer = ({ cardname, needRedirect }: Props) => {
  const [data, loading, error] = useCardProfileQueryByUserName(cardname);

  if (error) {
    needRedirect(true);
    return <AbsoluteSpinner />;
  }
  if (!data || loading) return <AbsoluteSpinner />;

  if (!data) {
    needRedirect(true);
    return <AbsoluteSpinner />;
  }

  return <UserPage cardProfile={data} />;
};
