import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tabs } from '../../components/Tabs/Tabs';
import { UserPage } from '../OneLink/UserPage';
import { PhoneEmulator } from '../../components/PhoneEmulator/PhoneEmulator';
import { sortByPriority } from '../../utils/sortByPriority';
import { Links } from './Links';
import { CardProfileType, LinkSet, LinksTab } from '../OneLink/CardProfileType';
import { General, GeneralDataFormValues } from './General';
import {
  removeCardProfile,
  updateCardProfile,
  updateCardProfileSettings,
  updateLinksArray,
} from '../../helpers/endpoints';
import { convertArrayToUpdateLinkInputArray } from '../../types/UpdateLinkInput';
import { Settings, SettingsDataFormValues } from './Settings';
import { CopyLinkElement } from './CopyLinkElement';
import { AbsoluteSpinner } from '../../components/Spinner/AbsoluteSpinner';
import { SocialLinks } from './SocialLinks';
import { PreviewButton } from '../../components/PreviewButton/PreviewButton';

type ProfileProps = {
  cardProfile: CardProfileType;
  setCardProfile: (cardProfile: CardProfileType) => void;
  children?: JSX.Element;
};

export const Profile = ({ cardProfile, setCardProfile, children }: ProfileProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const updateLinks = (links: LinkSet[], toDelete?: LinkSet, toAdd?: LinkSet) => {
    const beforeAnyChange = { ...cardProfile };
    const linksSetToUpdate = [...cardProfile.links];

    if (toDelete) {
      const linkToDelete = linksSetToUpdate.find((el) => el.id === toDelete.id);
      if (linkToDelete) {
        const index = linksSetToUpdate.indexOf(linkToDelete);
        linksSetToUpdate.splice(index, 1);
      }
    }

    if (toAdd) {
      linksSetToUpdate.unshift(toAdd);
    }

    for (let i = 0; i < links.length; i += 1) {
      const newLink = links[i];
      const linkToChange = linksSetToUpdate.find((el) => el.id === newLink.id);
      if (linkToChange) {
        const index = linksSetToUpdate.indexOf(linkToChange);
        linksSetToUpdate.splice(index, 1, newLink);
      }
    }

    setCardProfile({ ...cardProfile, links: linksSetToUpdate });

    return new Promise<void>((resolve, reject) => {
      if (links.length === 1) {
        return resolve();
      }
      return updateLinksArray(convertArrayToUpdateLinkInputArray(links))
        .then(() => {
          return resolve();
        })
        .catch(() => {
          setCardProfile(beforeAnyChange);
          return reject();
        });
    });
  };

  const updateProfileImage = (updatedCardProfile: CardProfileType) => {
    const copyCardProfile = { ...cardProfile };
    copyCardProfile.profile_image = updatedCardProfile.profile_image;
    setCardProfile(copyCardProfile);
    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  const getLinks = (linkStab: LinksTab) => {
    return [...cardProfile.links.filter((link: LinkSet) => link.linkstab === linkStab)].sort(sortByPriority);
  };

  const onGeneralSave = (values: GeneralDataFormValues) => {
    return updateCardProfile(cardProfile.id, values)
      .then(() => {
        const copyCardProfile = { ...cardProfile };
        copyCardProfile.displayname = values.displayname;
        copyCardProfile.description = values.description;
        setCardProfile(copyCardProfile);
      })
      .catch((e) => {
        console.log('ERROR: ', e.response);
      });
  };

  const onSettingsSave = (values: SettingsDataFormValues) => {
    return updateCardProfileSettings(cardProfile.id, values)
      .then(() => {
        const copyCardProfile = { ...cardProfile };
        copyCardProfile.address = values.address;
        copyCardProfile.categories = values.categories;
        copyCardProfile.name = values.name;
        setCardProfile(copyCardProfile);
      })
      .catch((e) => {
        console.log('ERROR: ', e.response);
      });
  };

  const removeProfile = async () => {
    setLoading(true);
    await removeCardProfile(cardProfile.id);
    window.location.reload();
  };

  return (
    <Container>
      {loading && <AbsoluteSpinner />}
      <LeftSidebar>
        <>{children}</>
        <PreviewButtonContainer>
          <PreviewButton footerContent={<CopyLinkElement url={cardProfile.name} />}>
            {cardProfile ? <UserPage cardProfile={cardProfile} /> : <></>}
          </PreviewButton>
        </PreviewButtonContainer>
        <Tabs>
          <div title={t('Tabs.Links')}>
            <Links links={getLinks(LinksTab.LINK)} updateLinks={updateLinks} cardProfileId={cardProfile.id} />
          </div>
          <div title={t('Tabs.SocialLinks')}>
            <SocialLinks
              links={getLinks(LinksTab.SOCIAL_LINK)}
              linksTab={LinksTab.SOCIAL_LINK}
              updateLinks={updateLinks}
              cardProfileId={cardProfile.id}
            />
          </div>
          <div title={t('Tabs.General')}>
            <General updateProfileImage={updateProfileImage} cardProfile={cardProfile} save={onGeneralSave} />
          </div>
          <div title={t('Tabs.Settings')}>
            <Settings cardProfile={cardProfile} save={onSettingsSave} removeProfile={removeProfile} />
          </div>
        </Tabs>
      </LeftSidebar>
      <RightSidebar>
        <Preview cardProfile={cardProfile} />
      </RightSidebar>
    </Container>
  );
};

const Preview = ({ cardProfile }: { cardProfile: CardProfileType }) => {
  return (
    <>
      <CopyLinkElement url={cardProfile.name} />
      <StyledPhoneEmulator>{cardProfile ? <UserPage cardProfile={cardProfile} /> : <></>}</StyledPhoneEmulator>
    </>
  );
};

const RightSidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const PreviewButtonContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
`;

const StyledPhoneEmulator = styled(PhoneEmulator)`
  padding: 0 50px;
`;

const LeftSidebar = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
`;
