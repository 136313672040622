import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { ROUTES } from '../../routing/routesConstants';
import { AuthorizationButtons } from '../AuthorizationButtons/AuthorizationButtons';

export const NavBarItems = (props: { open: boolean }) => {
  const { open } = props;
  const { t } = useTranslation();

  return (
    <Items>
      <LeftMenu open={open}>
        {/* <MenuItem onClick={() => navigate(ROUTES.BLOG)}>{t('MenuItem.Blog')}</MenuItem> */}
        {/* <MenuItem onClick={() => navigate(ROUTES.PRICING)}>{t('MenuItem.Pricing')}</MenuItem> */}
        {/* <MenuItem onClick={() => navigate(ROUTES.HELP)}>{t('MenuItem.Help')}</MenuItem> */}
        <MenuItem onClick={() => navigate(ROUTES.USERS)}>{t('MenuItem.Users')}</MenuItem>
      </LeftMenu>
      <RightMenu open={open}>
        <AuthorizationButtons open={open} />
      </RightMenu>
    </Items>
  );
};

const Items = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-left: 166px;
  width: inherit;
`;

const LeftMenu = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    background-color: #f8f9fa;
    flex-direction: column;
    width: 100%;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    overflow: hidden;
    position: absolute;
    top: 56px;
    left: 0;
  }
`;

const RightMenu = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: end;

  @media (max-width: 768px) {
    background-color: #f8f9fa;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 88px;
    left: 0;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    padding-bottom: 1rem;
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  color: rgb(0, 0, 0, 0.5);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  user-select: none;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    letter-spacing: 1px;
  }

  :hover {
    color: rgba(0, 0, 0, 0.7);
  }
`;
