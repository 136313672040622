// @flow
import * as React from 'react';

export const EditLinkImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
      <rect width="58" height="58" rx="10" fill="#EDEFF4" fillOpacity="0.53" />
      <g clipPath="url(#clip0)">
        <path
          d="M36.3051 31.8202C36.0025 31.8202 35.7572 32.0655 35.7572 32.3681V37.233C35.7562 38.1405 35.021 38.8759 34.1135 38.8767H18.7396C17.8321 38.8759 17.0969 38.1405 17.0958 37.233V22.9549C17.0969 22.0476 17.8321 21.3122 18.7396 21.3112H23.6044C23.9071 21.3112 24.1524 21.0659 24.1524 20.7632C24.1524 20.4608 23.9071 20.2153 23.6044 20.2153H18.7396C17.2272 20.217 16.0017 21.4426 16 22.9549V37.2332C16.0017 38.7455 17.2272 39.9711 18.7396 39.9728H34.1135C35.6258 39.9711 36.8513 38.7455 36.853 37.2332V32.3681C36.853 32.0655 36.6078 31.8202 36.3051 31.8202V31.8202Z"
          fill="#4003FF"
        />
        <path
          d="M36.636 18.8056C35.6731 17.8426 34.112 17.8426 33.1491 18.8056L23.3739 28.5807C23.3069 28.6477 23.2586 28.7307 23.2333 28.8219L21.9479 33.4627C21.895 33.653 21.9487 33.8567 22.0883 33.9965C22.228 34.136 22.4318 34.1897 22.622 34.1371L27.2628 32.8514C27.354 32.8262 27.437 32.7778 27.504 32.7108L32.3915 27.8231L37.279 22.9355C38.2404 21.9719 38.2404 20.4121 37.279 19.4485L36.636 18.8056ZM24.5678 28.9368L32.568 20.9364L35.1481 23.5166L27.1477 31.517L24.5678 28.9368ZM24.0524 29.971L26.1137 32.0325L23.2624 32.8225L24.0524 29.971ZM36.5042 22.1607L35.9231 22.7418L33.3427 20.1614L33.9241 19.5803C34.4589 19.0455 35.3262 19.0455 35.861 19.5803L36.5042 20.2233C37.0382 20.7588 37.0382 21.6254 36.5042 22.1607Z"
          fill="#4003FF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" transform="translate(16 18)" />
        </clipPath>
      </defs>
    </svg>
  );
};
