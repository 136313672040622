// @flow
import * as React from 'react';
import styled from 'styled-components';

export const RemoveButton = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill="#C44141" />
      <rect
        width="19.2487"
        height="3.84975"
        rx="1.92487"
        transform="matrix(0.707098 -0.707116 0.707098 0.707116 9.33325 22.9444)"
        fill="white"
      />
      <rect
        width="19.2487"
        height="3.84975"
        rx="1.92487"
        transform="matrix(0.707098 0.707116 -0.707098 0.707116 12.0557 9.33334)"
        fill="white"
      />
    </SVG>
  );
};

const SVG = styled.svg`
  cursor: pointer;
  :hover circle {
    fill: #a92727;
  }
`;
