import * as React from 'react';
import styled from 'styled-components';

export const AcceptButton = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <circle cx="17.5" cy="17.5" r="17.5" fill="#21B727" />
      <rect
        width="16.347"
        height="3.84975"
        rx="1.92487"
        transform="matrix(0.71351 -0.700645 0.700627 0.713528 12.9292 21.4535)"
        fill="white"
      />
      <rect
        width="10.4103"
        height="3.84562"
        rx="1.92281"
        transform="matrix(0.693101 0.72084 -0.720823 0.693119 10.772 13.6837)"
        fill="white"
      />
    </SVG>
  );
};

const SVG = styled.svg`
  cursor: pointer;
  :hover circle {
    fill: #158a0b;
  }
`;
