import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { LayoutDefault } from '../../components/Layouts/LayoutDefault';

export const NotFound = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  return (
    <LayoutDefault>
      <Center>
        <Error>
          <Number>4</Number>
          <Illustration>
            <Circle />
            <Clip>
              <Paper>
                <Face>
                  <Eyes>
                    <Eye type="left" />
                    <Eye type="right" />
                  </Eyes>
                  <RosyCheeks type="left" />
                  <RosyCheeks type="right" />
                  <Mouth />
                </Face>
              </Paper>
            </Clip>
          </Illustration>
          <Number>4</Number>
        </Error>
        <Text>{t('NotFoundMessage')}</Text>
      </Center>
      <By className="by">
        Illustration Cezan - 404 Page Not Found by
        <ByLink className="byLink" href="https://dribbble.com/shots/3965778-Cezan-404-Page-Not-Found" target="_blank">
          Masoud Ardestani
        </ByLink>
      </By>
    </LayoutDefault>
  );
};

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Error = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const Number = styled.div`
  font-weight: 900;
  font-size: 15rem;
  line-height: 1;
`;

const Illustration = styled.div`
  position: relative;
  width: 12.2rem;
  margin: 0 2.1rem;
`;

const Circle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 12.2rem;
  height: 11.4rem;
  border-radius: 50%;
  background-color: #293b49;
`;

const Clip = styled.div`
  position: absolute;
  bottom: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: 12.5rem;
  height: 13rem;
  border-radius: 0 0 50% 50%;
`;

const Paper = styled.div`
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 9.2rem;
  height: 12.4rem;
  border: 0.3rem solid #293b49;
  background-color: white;
  border-radius: 0.8rem;

  :before {
    content: '';
    position: absolute;
    top: -0.7rem;
    right: -0.7rem;
    width: 1.4rem;
    height: 1rem;
    background-color: white;
    border-bottom: 0.3rem solid #293b49;
    transform: rotate(45deg);
  }
`;

const Face = styled.div`
  position: relative;
  margin-top: 2.3rem;
`;

const Eyes = styled.div`
  position: absolute;
  top: 0;
  left: 2.4rem;
  width: 4.6rem;
  height: 0.8rem;
`;

const Eye = styled.div<{ type: 'left' | 'right' }>`
  position: absolute;
  bottom: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #293b49;
  animation-name: eye;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;


${(props) =>
  props.type === 'left'
    ? css`
        left: 0;
      `
    : css`
        right: 0;
      `}
}

@keyframes eye {
  0% {
    height: 0.8rem;
  }
  50% {
    height: 0.8rem;
  }
  52% {
    height: 0.1rem;
  }
  54% {
    height: 0.8rem;
  }
  100% {
    height: 0.8rem;
  }
`;

const RosyCheeks = styled.div<{ type: 'left' | 'right' }>`
  position: absolute;
  top: 1.6rem;
  width: 1rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: #fdabaf;

  ${(props) =>
    props.type === 'left'
      ? css`
          left: 1.4rem;
        `
      : css`
          right: 1.4rem;
        `}
`;

const Mouth = styled.div`
  position: absolute;
  top: 3.1rem;
  left: 50%;
  width: 1.6rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  transform: translateX(-50%);
  background-color: #293b49;
`;

const Text = styled.div`
  margin-top: 5rem;
  font-weight: 300;
  color: #293b49;
`;

const By = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  text-transform: uppercase;
  color: #293b49;
  font-size: 0.5rem;
`;
const ByLink = styled.a`
  text-decoration: none;
`;
