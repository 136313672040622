// @flow
import * as React from 'react';
import styled from 'styled-components';

export const AddButton = ({ className }: { className?: string }) => {
  return (
    <SVG
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
    >
      <circle cx="26" cy="26" r="26" fill="#027BFF" />
      <rect x="39" y="24" width="4" height="26" transform="rotate(90 39 24)" fill="white" />
      <rect x="39" y="24" width="4" height="26" transform="rotate(90 39 24)" fill="white" />
      <rect x="28" y="39" width="4" height="26" transform="rotate(-180 28 39)" fill="white" />
      <rect x="28" y="39" width="4" height="26" transform="rotate(-180 28 39)" fill="white" />
    </SVG>
  );
};

const SVG = styled.svg`
  cursor: pointer;
  :hover circle {
    fill: #2d8cf3;
  }
`;
