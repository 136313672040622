import styled from 'styled-components';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { Dots } from '../../assets/icons/Dots';
import { SmallSpinner } from '../../components/Spinner/SmallSpinner';
import { RemoveButton } from '../../assets/icons/RemoveButton';
import { AcceptButton } from '../../assets/icons/AcceptButton';
import { SelectSocial } from '../../components/SelectSocial/SelectSocial';
import links from '../../components/SelectSocial/links';
import { socials } from '../../components/SelectSocial/data';
import { LinkBoxFromValues } from './types/LinkBoxFromValues';
import { createNewLink, getLink, uploadLinkFile } from '../../helpers/endpoints';
import { convertToCreateLinkInput } from '../../types/CreateLinkInput';
import { LinkSet, LinksTab } from '../OneLink/CardProfileType';
import { svgToPng } from '../../utils/svgToPng';
import { getByteArrays } from '../../utils/getByteArrays';

type AddSocialLinkBoxProps = {
  updateState: (updatedLink: LinkSet) => Promise<void>;
  onRemove: () => Promise<void>;
  cardProfileId: number;
};

type AddSocialLinkBoxFormValues = { socialName: string; socialType: string };

export const AddSocialLinkBox = (props: AddSocialLinkBoxProps) => {
  const { updateState, onRemove, cardProfileId } = props;

  const { t } = useTranslation();
  const initialValues: AddSocialLinkBoxFormValues = { socialName: '', socialType: '' };

  const onSubmit = (values: AddSocialLinkBoxFormValues) => {
    addNewLink();
  };

  const validationSchema = Yup.object({
    socialName: Yup.string().required(t('RequiredField')),
    socialType: Yup.string().required(t('RequiredField')),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialStatus: 'NEW',
  });

  const addNewLink = async () => {
    if (formik.isValid && formik.status === '') {
      formik.setSubmitting(true);

      const svg = socials.find((s) => s.name === formik.values.socialType);
      if (svg) {
        const saveData = {
          name: formik.values.socialName,
          target: `${getUrl(formik.values.socialType)}${formik.values.socialName}`,
        };

        const toSave: LinkBoxFromValues = {
          name: saveData.name,
          target: saveData.target,
          asIcon: true,
          active: true,
        };
        const result = await createNewLink(convertToCreateLinkInput(toSave, cardProfileId, LinksTab.SOCIAL_LINK));

        const formData = new FormData();
        const str = renderToStaticMarkup(svg.icon);
        svgToPng(str, async (imgData: string) => {
          const file: File = new File(getByteArrays(imgData), 'icon-social.png', { type: 'image/png' });
          formData.append('thumbnail', file, file.name);

          await uploadLinkFile(result.data.id, formData);
          const updatedLink = await getLink(result.data.id);
          formik.setSubmitting(false);

          updateState(updatedLink.data);
        });
      }
    }
  };

  const removeLink = async () => {
    formik.setSubmitting(true);
    await onRemove();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setStatus('');
    formik.handleChange(e);
  };

  const onChangeSocial = (value: string) => {
    formik.setValues({ ...formik.values, socialType: value });
  };

  return (
    <LinkContainer>
      <Content>
        {formik.isSubmitting ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <DraggableElem>
            <StyledDots />
          </DraggableElem>
        )}
        <FormContainer>
          <ImageContainer>
            <SelectSocial onChangeSocial={onChangeSocial} />
          </ImageContainer>
          <InputsContainer>
            <div>{getUrl(formik.values.socialType)}</div>
            <UrlName>
              <NameInput
                onChange={handleChange}
                name="socialName"
                type="text"
                value={formik.values.socialName}
                placeholder="..."
                disabled={formik.isSubmitting}
              />
            </UrlName>
          </InputsContainer>
          <Content>
            <>
              <AcceptContainer onClick={addNewLink}>
                <AcceptButton />
              </AcceptContainer>
              <RemoveContainer onClick={removeLink}>
                <RemoveButton />
              </RemoveContainer>
            </>
          </Content>
          <input
            hidden
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          />
        </FormContainer>
      </Content>
    </LinkContainer>
  );
};

const getUrl = (social: string) => links[social] || 'Nazwa';

const LinkContainer = styled.div`
  margin-top: 5px;
  border-radius: 10px;
  padding: 11px 14px 11px 14px;
  position: relative;
  border: 1px solid #dee3ed;
  background: #f2f3f8;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const UrlName = styled.div`
  flex-grow: 1;
`;
const StyledDots = styled(Dots)`
  margin-right: 15px;
`;

const DraggableElem = styled.div``;

const Input = styled.input`
  background-color: transparent;
  border: 0;

  width: 100%;
  :focus {
    outline: none;
  }
`;
const NameInput = styled(Input)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  background: #edeff4;
  border: 1px dashed #e2e5eb;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  align-items: center;

  position: relative;
  cursor: pointer;
`;

const InputsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
`;

const AcceptContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

const Spinner = styled(SmallSpinner)`
  margin-right: 10px;
`;
