// @flow
import * as React from 'react';
import styled from 'styled-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { createEmptyCardProfile } from '../../helpers/endpoints';
import { NewProfileNameInput } from '../../components/NewProfileNameInput/NewProfileNameInput';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: { id: number; name: string }) => void;
  userId: number;
};

export const CreateNewCardProfileModal = (props: Props) => {
  const { open, onClose, onSuccess, userId } = props;
  const [loading, setLoading] = useState<boolean>();
  const [cardName, setCardName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(false);
    setError('');
    setCardName('');
  }, [onSuccess, onClose]);

  const createNewCardProfile = async () => {
    if (!cardName) {
      setError('Card name is required!');
      return;
    }

    setLoading(true);
    try {
      const response = await createEmptyCardProfile(userId, `${cardName}`);
      if (response && response.data) {
        onSuccess(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data) {
        setError('Profile with this name already exists.');
      } else {
        setError('Something wrong try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const CreateCardProfileButtons = () => {
    return (
      <>
        <StyledButton onClick={createNewCardProfile}>{t('AddNew')}</StyledButton>
        <Button onClick={onClose}>{t('Cancel')}</Button>
      </>
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCardName(event.target.value);
    setError('');
  };

  return open ? (
    <Modal
      size="small"
      footerContent={<CreateCardProfileButtons />}
      id="createNewCardProfile"
      title={t('CreateNewCardTitle')}
    >
      <Container>
        <NewProfileNameInput onChange={onChange} cardName={cardName} loading={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
      </Container>
    </Modal>
  ) : (
    <></>
  );
};

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
`;

const ErrorBox = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #cb2c2c;
  color: white;
`;
