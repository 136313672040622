// @flow
import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LoopVideo } from './LoopVideo';
import { SectionPhoneVideo } from './SectionPhoneVideo';

export const WelcomeSections = () => {
  const { t } = useTranslation();
  return (
    <SectionsContainer>
      <Section>
        <LoopVideo />
        <SectionText>{t('WelcomePage.LoopText')}</SectionText>
      </Section>
      <ReverseWrappedSection>
        <SectionText>{t('WelcomePage.PhoneAnimText')}</SectionText>
        <SectionPhoneVideo />
      </ReverseWrappedSection>
    </SectionsContainer>
  );
};

const SectionsContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #f8f9fa;
  margin: 1rem 0;
`;

const ReverseWrappedSection = styled(Section)`
  flex-wrap: wrap-reverse;
`;

const SectionText = styled.div`
  font-size: 2rem;
  margin: 2rem 0;
  font-weight: 500;
  line-height: 1.2;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 1.375rem;
    width: 75%;
  }
`;
