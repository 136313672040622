import * as React from 'react';

export const ShareIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <circle cx="14.5" cy="3.5" r="3" stroke="black" />
      <circle cx="3.5" cy="10.5" r="3" stroke="black" />
      <circle cx="14.5" cy="16.5" r="3" stroke="black" />
      <line x1="11.7764" y1="15.4472" x2="5.77639" y2="12.4472" stroke="black" />
      <line x1="11.2572" y1="5.42875" x2="6.25725" y2="8.42875" stroke="black" />
    </svg>
  );
};
