import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type CarouselProps = {
  elements: string[];
  staticText: string;
  className?: string;
};

export const Carousel = (props: CarouselProps) => {
  const { elements, staticText, className } = props;
  const { t } = useTranslation();
  const translatedElements = elements.map((elem) => t(elem));
  const [currentIndex, setCurrentIndex] = useState(0);

  const animationDelay = 2000;

  useEffect(() => {
    const interval = animateHeadline();
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const animateHeadline = () => {
    return setInterval(() => {
      setNextIndex();
    }, animationDelay);
  };

  const setNextIndex = () => {
    if (currentIndex < translatedElements.length - 1) {
      setCurrentIndex((prevValue) => {
        if (prevValue < translatedElements.length - 1) {
          return prevValue + 1;
        }
        return 0;
      });
    }
  };

  const animatedTexts = () =>
    translatedElements.map((word, index) => {
      return (
        <AnimatedText key={word} isVisible={currentIndex === index}>
          {word}
        </AnimatedText>
      );
    });

  return (
    <Wrapper className={className}>
      <Headline>
        {staticText}
        <AnimatedTextContainer>{animatedTexts()}</AnimatedTextContainer>
      </Headline>
    </Wrapper>
  );
};

const cdRotateIn = keyframes`
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
`;

const cdRotateOut = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  35% {
    transform: rotateX(-40deg);
  }
  100% {
    transform: rotateX(180deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
`;

const Headline = styled.h1`
  display: flex;
  flex-flow: column;
  width: 600px;
  margin-top: 0;
  font-size: 3rem;

  @media (max-width: 650px) {
    font-size: 2rem;
    width: 400px;
  }

  @media (max-width: 450px) {
    font-size: 28px;
    width: 350px;
  }

  @media (max-width: 360px) {
    font-size: 22px;
    width: 280px;
  }
`;

const AnimatedTextContainer = styled.span`
  display: flex;
  perspective: 600px;
  height: 3rem;

  @media (max-width: 650px) {
    perspective: 400px;
  }

  @media (max-width: 450px) {
    perspective: 350px;
  }

  @media (max-width: 360px) {
    perspective: 280px;
  }
`;

const AnimatedText = styled.span<{ isVisible: boolean }>`
  opacity: 0;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 600px;
  justify-content: center;
  transition: 1s opacity ease;

  ${({ isVisible }) =>
    isVisible &&
    css`
      position: relative;
      opacity: 1;
      transform: rotateX(0deg);
      transition: 1s opacity ease;
      animation: ${cdRotateIn} 1.2s;
    `};

  ${({ isVisible }) =>
    !isVisible &&
    css`
      transition: 0.8s opacity ease;
      transform: rotateX(180deg);
      animation: ${cdRotateOut} 1.2s;
    `};

  @media (max-width: 650px) {
    width: 400px;
  }

  @media (max-width: 450px) {
    width: 350px;
  }

  @media (max-width: 360px) {
    width: 280px;
  }
`;
