import React, { FunctionComponent } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LayoutDefault } from '../../components/Layouts/LayoutDefault';
import { RegisterForm } from './RegisterForm';
import logo from '../../assets/images/logo.png';
import { ROUTES } from '../../routing/routesConstants';

type StateType = {
  key: string;
  username: string;
};

const Register: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { username } = location.state as StateType;

  return (
    <LayoutDefault>
      <Container>
        <Logo src={logo} />
        <HeaderText>{t('RegisterPage.Header')}</HeaderText>
        <StyledRegisterForm newUsername={username} />
        <Terms>{t('RegisterPage.Terms')}</Terms>
        <HaveAccount onClick={() => navigate(ROUTES.LOGIN)}>{t('RegisterPage.HaveAccount')}</HaveAccount>
      </Container>
    </LayoutDefault>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`;

const Terms = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #86898d;
  margin-top: 47px;
`;

const HaveAccount = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-top: 33px;

  cursor: pointer;
`;

const Logo = styled.img`
  height: 48px;
`;

const HeaderText = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin-top: 11px;
`;

const StyledRegisterForm = styled(RegisterForm)`
  margin-top: 36px;
`;

export default Register;
