// @flow
import * as React from 'react';

export const Logout = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.97012 16.475H2.24251C1.82988 16.475 1.49502 16.1401 1.49502 15.7275V2.27233C1.49502 1.8597 1.82992 1.52484 2.24251 1.52484H8.97012C9.38348 1.52484 9.71761 1.19071 9.71761 0.777348C9.71761 0.36398 9.38348 0.0297852 8.97012 0.0297852H2.24251C1.00614 0.0297852 0 1.03596 0 2.27233V15.7275C0 16.9638 1.00614 17.97 2.24251 17.97H8.97012C9.38348 17.97 9.71761 17.6359 9.71761 17.2225C9.71761 16.8091 9.38348 16.475 8.97012 16.475Z"
        fill="#000000"
      />
      <path
        d="M17.7771 8.46784L13.2323 3.98278C12.9393 3.69274 12.4654 3.6965 12.1753 3.99027C11.8853 4.28403 11.8883 4.7572 12.1828 5.04724L15.4307 8.25254H6.72747C6.31411 8.25254 5.97998 8.58667 5.97998 9.00003C5.97998 9.4134 6.31411 9.74756 6.72747 9.74756H15.4307L12.1828 12.9529C11.8883 13.2429 11.8861 13.7161 12.1753 14.0098C12.3218 14.1578 12.5147 14.2326 12.7075 14.2326C12.8974 14.2326 13.0873 14.1608 13.2323 14.0173L17.7771 9.53223C17.9192 9.39171 17.9999 9.20032 17.9999 9C17.9999 8.79975 17.9199 8.60913 17.7771 8.46784Z"
        fill="#000000"
      />
    </svg>
  );
};
