import * as React from 'react';

export const Help = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 12.1816C18 9.55797 16.2322 7.26567 13.7392 6.57491C13.5825 2.92291 10.5629 0 6.87305 0C3.08318 0 0 3.08318 0 6.87305C0 8.10818 0.328766 9.31078 0.953064 10.3666L0.0252686 13.7207L3.37953 12.793C4.35004 13.3669 5.44482 13.6902 6.57477 13.739C7.2654 16.232 9.55783 18 12.1816 18C13.2289 18 14.2473 17.7211 15.1422 17.1911L17.9746 17.9746L17.1911 15.1422C17.7211 14.2473 18 13.2289 18 12.1816ZM3.54597 11.6526L1.53795 12.2081L2.09344 10.2001L1.96683 10.0021C1.37013 9.06853 1.05469 7.98651 1.05469 6.87305C1.05469 3.66476 3.66476 1.05469 6.87305 1.05469C10.0813 1.05469 12.6914 3.66476 12.6914 6.87305C12.6914 10.0813 10.0813 12.6914 6.87305 12.6914C5.75958 12.6914 4.6777 12.376 3.744 11.7793L3.54597 11.6526ZM16.4621 16.4621L14.972 16.0498L14.773 16.1793C14.0017 16.6804 13.1055 16.9453 12.1816 16.9453C10.1195 16.9453 8.30745 15.612 7.66544 13.7001C10.8232 13.336 13.336 10.8232 13.7002 7.6653C15.612 8.30745 16.9453 10.1195 16.9453 12.1816C16.9453 13.1055 16.6804 14.0017 16.1793 14.773L16.0498 14.972L16.4621 16.4621Z"
        fill="black"
      />
      <path d="M6.3457 9.52734H7.40039V10.582H6.3457V9.52734Z" fill="black" />
      <path
        d="M7.92773 5.27344C7.92773 5.57295 7.80579 5.8494 7.58441 6.05196L6.3457 7.18575V8.47266H7.40039V7.65019L8.29646 6.83006C8.73235 6.43112 8.98242 5.86382 8.98242 5.27344C8.98242 4.11026 8.03622 3.16406 6.87305 3.16406C5.70987 3.16406 4.76367 4.11026 4.76367 5.27344H5.81836C5.81836 4.69185 6.29146 4.21875 6.87305 4.21875C7.45464 4.21875 7.92773 4.69185 7.92773 5.27344Z"
        fill="black"
      />
    </svg>
  );
};
