import * as React from 'react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import phoneAnimation from '../../assets/animations/phoneAnim.mp4';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';

export const SectionPhoneVideo = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <VideoContainer>
      {isLoading && <CentredSpinner />}
      <StyledVideo muted autoPlay loop playsInline onLoadedMetadata={() => setIsLoading(false)}>
        <source src={phoneAnimation} />
      </StyledVideo>
    </VideoContainer>
  );
};

const StyledVideo = styled.video`
  width: 360px;

  @media (max-width: 400px) {
    width: 300px;
  }
`;

const VideoContainer = styled.div`
  display: flex;

  @media (max-width: 400px) {
    width: 300px;
  }
`;
