import * as React from 'react';
import { SVGProps } from 'react';

export const QrCode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.375 5.625C19.0298 5.625 18.75 5.34516 18.75 5V1.79688C18.75 1.49531 18.5047 1.25 18.2031 1.25H15C14.6548 1.25 14.375 0.970156 14.375 0.625C14.375 0.279844 14.6548 0 15 0H18.2031C19.1939 0 20 0.806094 20 1.79688V5C20 5.34516 19.7202 5.625 19.375 5.625Z"
        fill="black"
      />
      <path
        d="M0.625 5.625C0.279805 5.625 0 5.34516 0 5V1.79688C0 0.806094 0.806094 0 1.79688 0H5C5.3452 0 5.625 0.279844 5.625 0.625C5.625 0.970156 5.3452 1.25 5 1.25H1.79688C1.49531 1.25 1.25 1.49531 1.25 1.79688V5C1.25 5.34516 0.970195 5.625 0.625 5.625Z"
        fill="black"
      />
      <path
        d="M5 20H1.79688C0.806094 20 0 19.1939 0 18.2031V15C0 14.6548 0.279805 14.375 0.625 14.375C0.970195 14.375 1.25 14.6548 1.25 15V18.2031C1.25 18.5047 1.49531 18.75 1.79688 18.75H5C5.3452 18.75 5.625 19.0298 5.625 19.375C5.625 19.7202 5.3452 20 5 20Z"
        fill="black"
      />
      <path
        d="M18.2031 20H15C14.6548 20 14.375 19.7202 14.375 19.375C14.375 19.0298 14.6548 18.75 15 18.75H18.2031C18.5047 18.75 18.75 18.5047 18.75 18.2031V15C18.75 14.6548 19.0298 14.375 19.375 14.375C19.7202 14.375 20 14.6548 20 15V18.2031C20 19.1939 19.1939 20 18.2031 20Z"
        fill="black"
      />
      <path
        d="M7.57812 9.375H4.29688C3.30609 9.375 2.5 8.56891 2.5 7.57812V4.29688C2.5 3.30609 3.30609 2.5 4.29688 2.5H7.57812C8.56891 2.5 9.375 3.30609 9.375 4.29688V7.57812C9.375 8.56891 8.56891 9.375 7.57812 9.375ZM4.29688 3.75C3.99531 3.75 3.75 3.99531 3.75 4.29688V7.57812C3.75 7.87969 3.99531 8.125 4.29688 8.125H7.57812C7.87969 8.125 8.125 7.87969 8.125 7.57812V4.29688C8.125 3.99531 7.87969 3.75 7.57812 3.75H4.29688Z"
        fill="black"
      />
      <path
        d="M7.57812 17.5H4.29688C3.30609 17.5 2.5 16.6939 2.5 15.7031V12.4219C2.5 11.4311 3.30609 10.625 4.29688 10.625H7.57812C8.56891 10.625 9.375 11.4311 9.375 12.4219V15.7031C9.375 16.6939 8.56891 17.5 7.57812 17.5ZM4.29688 11.875C3.99531 11.875 3.75 12.1203 3.75 12.4219V15.7031C3.75 16.0047 3.99531 16.25 4.29688 16.25H7.57812C7.87969 16.25 8.125 16.0047 8.125 15.7031V12.4219C8.125 12.1203 7.87969 11.875 7.57812 11.875H4.29688Z"
        fill="black"
      />
      <path
        d="M15.7031 9.375H12.4219C11.4311 9.375 10.625 8.56891 10.625 7.57812V4.29688C10.625 3.30609 11.4311 2.5 12.4219 2.5H15.7031C16.6939 2.5 17.5 3.30609 17.5 4.29688V7.57812C17.5 8.56891 16.6939 9.375 15.7031 9.375ZM12.4219 3.75C12.1203 3.75 11.875 3.99531 11.875 4.29688V7.57812C11.875 7.87969 12.1203 8.125 12.4219 8.125H15.7031C16.0047 8.125 16.25 7.87969 16.25 7.57812V4.29688C16.25 3.99531 16.0047 3.75 15.7031 3.75H12.4219Z"
        fill="black"
      />
      <path
        d="M6.25 6.875H5.625C5.2798 6.875 5 6.59516 5 6.25V5.625C5 5.27984 5.2798 5 5.625 5H6.25C6.5952 5 6.875 5.27984 6.875 5.625V6.25C6.875 6.59516 6.5952 6.875 6.25 6.875Z"
        fill="black"
      />
      <path
        d="M14.375 6.875H13.75C13.4048 6.875 13.125 6.59516 13.125 6.25V5.625C13.125 5.27984 13.4048 5 13.75 5H14.375C14.7202 5 15 5.27984 15 5.625V6.25C15 6.59516 14.7202 6.875 14.375 6.875Z"
        fill="black"
      />
      <path
        d="M6.25 15H5.625C5.2798 15 5 14.7202 5 14.375V13.75C5 13.4048 5.2798 13.125 5.625 13.125H6.25C6.5952 13.125 6.875 13.4048 6.875 13.75V14.375C6.875 14.7202 6.5952 15 6.25 15Z"
        fill="black"
      />
      <rect x="11.1719" y="11.1328" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="11.1719" y="11.1328" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="13.2031" y="13.1641" width="1.75781" height="1.83594" rx="0.878906" fill="black" />
      <rect x="13.2031" y="13.1641" width="1.75781" height="1.83594" rx="0.878906" fill="black" />
      <rect x="13.2031" y="11.1328" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="13.2031" y="11.1328" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="15.1562" y="13.1641" width="1.75781" height="1.83594" rx="0.878906" fill="black" />
      <rect x="15.1562" y="13.1641" width="1.75781" height="1.83594" rx="0.878906" fill="black" />
      <rect x="15.1562" y="15.2344" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="15.1562" y="15.2344" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="11.1719" y="15.2344" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
      <rect x="11.1719" y="15.2344" width="1.75781" height="1.79688" rx="0.878906" fill="black" />
    </svg>
  );
};
