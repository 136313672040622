import * as React from 'react';
import { useFormik } from 'formik';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';
import { useRef } from 'react';
import { Form, Input, TextArea } from '../../components/FormTheme';
import { CardProfileType } from '../OneLink/CardProfileType';
import { shallowEqual } from '../../utils/shallowEqual';
import { CentredSpinner } from '../../components/Spinner/CentredSpinner';
import defaultProfileImage from '../../assets/images/default-profile-image.svg';
import { FileUploader } from '../../components/FileUploader/FileUploader';
import { getCardProfileData, updateCardProfile, uploadProfileFile } from '../../helpers/endpoints';
import { EditProfileImageIcon } from '../../assets/icons/EditProfileImageIcon';
import { RemoveProfileImage } from '../../assets/icons/RemoveProfileImage';
import { AddProfileImageIcon } from '../../assets/icons/AddProfileImageIcon';

type Props = {
  cardProfile: CardProfileType;
  save: (values: GeneralDataFormValues) => Promise<void>;
  updateProfileImage: (cardProfile: CardProfileType) => Promise<void>;
};

export type GeneralDataFormValues = {
  displayname: string;
  description: string;
};

export const General = (props: Props) => {
  const editProfileSvg = encodeURIComponent(renderToStaticMarkup(<EditProfileImageIcon />));
  const addProfileSvg = encodeURIComponent(renderToStaticMarkup(<AddProfileImageIcon />));
  const { cardProfile, save, updateProfileImage } = props;
  const initialValues: GeneralDataFormValues = {
    displayname: cardProfile.displayname,
    description: cardProfile.description,
  };
  const fileUploader = useRef<HTMLInputElement>(null);

  const uploadProfileImage = async (file: File) => {
    const formData = new FormData();
    formData.append('profile_image', file, file.name);

    formik.setSubmitting(true);
    await uploadProfileFile(cardProfile.id, formData);
    const result = await getCardProfileData(cardProfile.id);
    const updatedCardProfile: CardProfileType = result.data;
    await updateProfileImage(updatedCardProfile);

    return new Promise<void>((resolve) => {
      resolve();
      formik.setSubmitting(false);
    });
  };

  const onSubmit = (values: GeneralDataFormValues) => {
    if (!shallowEqual(initialValues, values)) {
      save(values).then(() => {
        formik.setSubmitting(false);
      });
    } else {
      formik.setSubmitting(false);
    }
  };

  const saveOnBlur = (e: React.FocusEvent) => {
    formik.handleBlur(e);
    if (!shallowEqual(initialValues, formik.values)) {
      formik.setSubmitting(true);
      save(formik.values)
        .then(() => {
          formik.setSubmitting(false);
        })
        .catch((error) => {
          console.log('saveOnBlur: ', error);
          formik.setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const removeProfileImage = async () => {
    formik.setSubmitting(true);
    const { values } = formik;
    await updateCardProfile(cardProfile.id, { ...values, profile_image: null });
    const result = await getCardProfileData(cardProfile.id);
    const updatedCardProfile: CardProfileType = result.data;
    await updateProfileImage(updatedCardProfile);

    return new Promise<void>((resolve) => {
      resolve();
      formik.setSubmitting(false);
    });
  };

  const editProfileImage = () => {
    if (fileUploader.current) {
      fileUploader.current.click();
    }
  };

  return (
    <StyledForm>
      <ProfileImageContainer>
        {cardProfile.profile_image ? (
          <>
            <ProfileImage src={cardProfile.profile_image} alt="userImage" />
            <EditButton svgFile={editProfileSvg} onClick={editProfileImage} />
            <RemoveButton onClick={removeProfileImage} />{' '}
          </>
        ) : (
          <>
            <ProfileImage src={defaultProfileImage} alt="userImage" />
            <EditButton svgFile={addProfileSvg} onClick={editProfileImage} />
          </>
        )}
        <HiddenFileUploader ref={fileUploader} save={uploadProfileImage} />
      </ProfileImageContainer>
      <StyledInput
        onChange={formik.handleChange}
        name="displayname"
        type="text"
        value={formik.values.displayname}
        onBlur={saveOnBlur}
        placeholder="..."
        disabled={formik.isSubmitting}
      />
      <StyledTextArea
        onChange={formik.handleChange}
        name="description"
        value={formik.values.description}
        onBlur={saveOnBlur}
        placeholder="..."
        disabled={formik.isSubmitting}
      />
      {formik.isSubmitting && <CentredSpinner />}
      <input
        hidden
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </StyledForm>
  );
};

const HiddenFileUploader = styled(FileUploader)`
  display: none;
`;

const StyledForm = styled(Form)``;

const EditButton = styled.div<{ svgFile: string }>`
    position: absolute;
    background-repeat: no-repeat;
    height: 100%;
    display: none;
    top: 0;
    width: 100%;
   background-image: ${(props) => `url("data:image/svg+xml,${props.svgFile}"`});
`;

const RemoveButton = styled(RemoveProfileImage)`
  position: absolute;
  background-repeat: no-repeat;
  right: 0;
  display: none;
  top: 0;

  :hover circle {
    fill: #a85353;
  }
`;

const ProfileImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  :hover > ${EditButton} {
    display: block;
  }

  :hover > ${RemoveButton} {
    display: block;
  }
`;

const StyledInput = styled(Input)`
  text-align: center;
  margin-bottom: 10px;
`;

const StyledTextArea = styled(TextArea)`
  text-align: center;
  height: 200px;
`;

const ProfileImage = styled.img`
  display: inline-block;
  height: 6.2em;
  width: 6.2em;
  border-radius: 50%;
`;
