import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
  labelName: string;
  type: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
};

export const StyledInput = ({ labelName, ...props }: Props) => {
  return (
    <InputGroup>
      <Input {...props} placeholder="&nbsp;" />
      <Bar />
      <Label>{labelName}</Label>
    </InputGroup>
  );
};

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 35px;
`;

const Input = styled.input`
  box-sizing: border-box;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 5px; */
  box-shadow: none;

  &:-moz-placeholder {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }
`;

const Bar = styled.span`
  position: relative;
  display: block;
  width: 300px;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #3e00ff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    border-radius: 5px;
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }

  input:focus ~ &:before,
  input:focus ~ &:after {
    width: 50%;
  }
`;

const Label = styled.label`
  color: #ccc;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  input:focus ~ &,
  input:active ~ &,
  input:not(:placeholder-shown) ~ & {
    top: -20px;
    font-size: 14px;
    color: #3e00ff;
  }
`;
