import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useEffect } from 'react';
import { socials } from './data';

export const SelectSocial = (props: { onChangeSocial: (value: string) => void }) => {
  const options = socials.map(({ icon, name }: { icon: JSX.Element; name: string }) => ({
    value: name,
    label: createElement(icon, name),
  }));

  useEffect(() => {
    props.onChangeSocial(options[0].value);
  }, []);

  const onChange = ({ value }: { value: string }) => {
    props.onChangeSocial(value);
  };

  return (
    <StyledSelect
      onChange={onChange}
      isSearchable={false}
      options={options}
      classNamePrefix="social-select"
      defaultValue={options[0]}
    />
  );
};

const createElement = (icon: JSX.Element, name: string) => {
  return (
    <Elem>
      <Icon>{icon}</Icon>
      <div className="name">{name}</div>
    </Elem>
  );
};

const Elem = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  margin-right: 10px;
`;

const StyledSelect = styled(Select)`
  .social-select__control {
    width: 60px;
    height: 60px;
    background: transparent;
    border: none;
    cursor: pointer;

    :focus {
      outline: none;
      border: none;
    }
  }

  .social-select__value-container {
    padding: 5px 5px;
    height: 100%;
    overflow: hidden;
  }

  .social-select__single-value svg {
    width: 48px;
    height: 48px;
  }

  .social-select__single-value .name {
    display: none;
  }

  .social-select__indicators {
    display: none;
  }

  .social-select__menu {
    width: 200px;
  }

  .social-select__option {
    cursor: pointer;
  }
`;
