import axios from 'axios';
import { navigate } from '@reach/router';
import cookie from 'react-cookies';
import { ROUTES } from '../routing/routesConstants';
import { REFRESH_TOKEN_NAME, setCurrentUser } from './setCurrentUser';
import { removeCurrentUser } from './removeCurrentUser';
import { getCurrentUser } from './getCurrentUser';

const axiosInstance = () => {
  const headers: any = {};
  const accessToken = getCurrentUser();
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const axiosInst = axios.create({
    baseURL: process.env.APIURL,
    headers,
  });

  axiosInst.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled
      if (error.config.url === `/token/refresh/` || error.response.message === 'Account is disabled.') {
        removeCurrentUser();
        navigate(ROUTES.WELCOME).then(() => {
          window.location.reload();
        });

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      return getNewToken()
        .then((token) => {
          // New request with new token
          const configWithNewToken = error.config;
          configWithNewToken.headers.Authorization = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(configWithNewToken)
              .then((response) => {
                resolve(response);
              })
              .catch((e) => {
                reject(e);
              });
          });
        })
        .catch((e) => {
          removeCurrentUser();
          navigate(ROUTES.WELCOME).then(() => {
            window.location.reload();
          });
          Promise.reject(e);
        });
    },
  );

  return axiosInst;
};

const getNewToken = (): Promise<string> => {
  const refreshToken = cookie.load(REFRESH_TOKEN_NAME);

  return new Promise((resolve, reject) => {
    if (refreshToken) {
      axiosInstance()
        .post(`/token/refresh/`, { refresh: refreshToken })
        .then((response) => {
          const localStorageUser = {
            access: response.data.access,
            refresh: refreshToken,
          };
          setCurrentUser(localStorageUser);

          resolve(response.data.access);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject(new Error('refresh doesnt exist'));
    }
  });
};

export default axiosInstance();
