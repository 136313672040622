import React from 'react';
import { TabPanel } from './TabPanel';

type Props = {
  className?: string;
  children: JSX.Element[];
};
export const Tabs = (props: Props) => {
  const { children } = props;

  const handleTabChange = (index: number) => {
    // console.log('Selected tab index', index);
  };

  return (
    <TabPanel className={props.className} onTabChange={handleTabChange}>
      {children}
    </TabPanel>
  );
};
