import { LinkSet } from '../pages/OneLink/CardProfileType';
import { LinkBoxFromValues } from '../pages/Profile/types/LinkBoxFromValues';

export type UpdateLinkInput = {
  id: number;
  name?: string;
  target?: string;
  is_active?: boolean;
  is_icon?: boolean;
  priority?: number;
  thumbnail?: string | null;
};

export const convertToUpdateLinkInput = (values: LinkBoxFromValues) => {
  const result: UpdateLinkInput = {
    id: 1,
    name: values.name,
    target: values.target,
    is_active: values.active,
    is_icon: values.asIcon,
  };
  return result;
};

const convertToUpdateLinkInputFromLinkSet = (values: LinkSet) => {
  const result: UpdateLinkInput = {
    id: values.id,
    name: values.name,
    target: values.target,
    is_active: values.is_active,
    is_icon: values.is_icon,
    priority: values.priority,
  };
  return result;
};

export const convertArrayToUpdateLinkInputArray = (values: LinkSet[]) => {
  const result: UpdateLinkInput[] = [];
  values.forEach((val) => result.push(convertToUpdateLinkInputFromLinkSet(val)));
  return result;
};
