import { createContext } from 'react';
import { getCurrentUser } from './getCurrentUser';

type Action = { type: 'login' } | { type: 'logout' };
type Dispatch = (action: Action) => void;
export type UserType = {
  user: string;
};

export const reduceFunc = (state: UserType, action: Action) => {
  switch (action.type) {
    case 'login':
      return { user: getCurrentUser() };
    case 'logout':
      return { user: undefined };
    default:
      return undefined;
  }
};

export const UserStateContext = createContext<UserType | undefined>(undefined);
export const UserDispatchContext = createContext<Dispatch | undefined>(undefined);
