import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../helpers/axios';
import { getCurrentUser } from '../helpers/getCurrentUser';

export function useAxiosGet<T>(uri: string): [T | undefined, boolean, any, Dispatch<SetStateAction<T | undefined>>] {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = getCurrentUser();
      let config: AxiosRequestConfig = {};
      if (accessToken) {
        config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      }
      if (!loading) setLoading(true);
      await axiosInstance
        .get(uri, config)
        .then((result) => {
          setData(result.data);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    };
    fetchData();
  }, [uri]);

  return [data, loading, error, setData];
}
